const Keys = {
    TOKEN: "token",
};

const store = {
    set: (key, value) => {
        localStorage.setItem(key, value);
    },
    get: key => {
        return localStorage.getItem(key);
    }
};

export default {
    setToken: token => {
        store.set(Keys.TOKEN, token);
    },
    getToken: () => store.get(Keys.TOKEN),
    saveObject: (key, object) => {
        store.set(key, JSON.stringify(object));
    },
    getObject: (key) => {
        const value = store.get(key);
        return value ? JSON.parse(value) : null;
    }
};
