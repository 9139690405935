import React, { useState, useEffect, useContext } from "react";
import { Row, Table, Col, Select } from "antd";
import moment from "moment";

import { queryRequest } from "../common/utilities";
import EditDeleteButtons from "../common/components/tables/EditDeleteButtons";
import AddButton from "../common/components/tables/AddButton";
import menus from "core/application/menus/menu";
import { displayDateAndHour } from "../common/displayUtilities";
import { AppContext } from "../AppContext";

const Menu = () => {
  const { user } = useContext(AppContext);
  const [items, setItems] = useState();
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [defaultMenu, setDefaultMenu] = useState("1");
  const { Option } = Select;

  const updateMenus = async () => {
    setLoading(true);
    const availableMenu = await queryRequest(() => menus.getMenus());
    setMenu(availableMenu);
    let item = await queryRequest(() => menus.getAll(defaultMenu));
    setItems(item);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updateMenus();
    })();
  }, [defaultMenu]);

  const handleMenuChange = value => {
    setDefaultMenu(value);
  };

  const columns = [
    {
      key: "title",
      title: "Titulli",
      dataIndex: "title",
    },
    {
      key: "priority",
      title: "Prioriteti",
      dataIndex: "priority",
    },
    {
      key: "created",
      title: "Krijuar në",
      dataIndex: "created",
      render: value => displayDateAndHour(value)
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => {
        return (
          user.canDoAnything && (
            <EditDeleteButtons
              editUrl={`/menus/manage-menu/${defaultMenu}/${record.id}`}
              onOkDelete={() => menus.delete(record.id)}
              onDeleted={() => updateMenus()}
              disableDeleted={record.children !== null}
              deleteTitle={
                record.hasChildren ? "Nuk mund të fshihet sepse ka fëmijë." : ""
              }
            />
          )
        );
      }
    }
  ];
  return (
    <>
      <Row>
        <Col span={15}>
          <h4>Menutë</h4>
        </Col>
        <Col span={6}>
          <Select
            defaultValue={defaultMenu}
            onChange={handleMenuChange}
            style={{ width: 120 }}
            label="Zgjidh Menu"
            placeholder="Zgjidh Menu"
            name="menu"
            itemName="menu"
          >
            {menu.map(m => (
              <Option key={m.id} value={m.id}>
                {m.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={3}>
          <div className={"text-right"}>
            <AddButton addUrl={`/menus/manage-menu/${defaultMenu}`} />
          </div>
        </Col>
      </Row>

      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={items}
        loading={loading}
      />
    </>
  );
};

export default Menu;
