import React, { useContext, useState, useEffect } from "react";
import { Row, Table, Col, Spin, Button } from "antd";
import { useHistory } from "react-router-dom";
import galleries from "core/application/galleries/galleries";
import { queryRequest } from "../common/utilities";

import TableEditButton from "../common/components/tables/TableEditButton";
import TableDeleteButton from "../common/components/tables/TableDeleteButton";
import AddButton from "../common/components/tables/AddButton";
import getSiteUrl from "../siteUrlCreator";

import { AppContext } from "../AppContext";

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

const Galleries = () => {
  let history = useHistory();
  const { user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateTable = async () => {
    setLoading(true);

    const items = await queryRequest(() => galleries.getGalleries());
    setData(items);

    setLoading(false);
  };

  const columns = [
    {
      title: "Emri",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            className="mr-8"
            icon="file-image"
            onClick={() => {
              history.push(getSiteUrl(`/gallery-items/${record.friendlyUrl}`));
            }}
          ></Button>
          <TableEditButton
            className="mr-16"
            editUrl={`/galleries/manage-gallery/${record.id}`}
            visible={user.canSaveArticle}
          />
          <TableDeleteButton
            onDelete={() => galleries.delete(record.id)}
            onDeleted={() => updateTable()}
            visible={user.canDoAnything}
          />
        </>
      )
    }
  ];

  useEffect(() => {
    updateTable();
  }, []);

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={16}>
          <h4>Galeritë</h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton addUrl={getSiteUrl(`/galleries/manage-gallery`)} />
          </div>
        </Col>
      </Row>
      <Table columns={columns} dataSource={data} onChange={onChange} />
    </Spin>
  );
};

export default Galleries;
