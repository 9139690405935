import React from "react";
import ProtectedRoute from "../common/configs/routes/protectedRoute";
import { Switch } from "react-router-dom";

import Menu from './Menu';
import ManageMenu from './ManageMenu';

const MENUS_ROOT_PATH = "/menus";

const mainRoute = {
  icon: "pie-chart",
  key: "menu-10",
  path: `${MENUS_ROOT_PATH}`,
  name: "Menutë"
};

const MenuRouter = () => {
  return (
    <Switch>
      <ProtectedRoute exact path={`${MENUS_ROOT_PATH}/`}>
        <Menu />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${MENUS_ROOT_PATH}/manage-menu/:menuId/:id?`}>
        <ManageMenu />
      </ProtectedRoute>
    </Switch>
  );
};

export { MenuRouter, MENUS_ROOT_PATH, mainRoute };
