import { Switch } from "react-router-dom";
import React from "react";

import ProtectedRoute from "../common/configs/routes/protectedRoute";
import Articles from "../articles/Articles";
import ManageArticle from "../articles/ManageArticle";
import BlogCategories from "../articles/categories/BlogCategories";
import ManageBlogCategory from "../articles/categories/ManageBlogCategory";
import getLoginUser from "core/application/users/getLoginUser";
import Permission from "core/domain/users/Permission";
import Video from "../videos/Video";
import ManageVideo from "../videos/ManageVideo";

const RTSH_SPORT_ROOT_PATH = "/rtshSport";

const user = getLoginUser();

const rtshSportRoutes = [
  {
    icon: "pie-chart",
    key: "menu-1",
    path: `${RTSH_SPORT_ROOT_PATH}/articles`,
    name: "Artikuj"
  }
];

if (user && user.canDoAnything) {
  rtshSportRoutes.push({
    icon: "pie-chart",
    key: "menu-2",
    path: `${RTSH_SPORT_ROOT_PATH}/blogCategories`,
    name: "Kategoritë"
  });

  rtshSportRoutes.push({
    icon: "pie-chart",
    key: "menu-6",
    path: `${RTSH_SPORT_ROOT_PATH}/video`,
    name: "Video"
  });
}

const RtshSportRouter = () => (
  <Switch>
    <ProtectedRoute exact path={`${RTSH_SPORT_ROOT_PATH}/articles`}>
      <Articles />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[
        Permission.All,
        Permission.PublishArticle,
        Permission.SaveArticle
      ]}
      exact
      path={`${RTSH_SPORT_ROOT_PATH}/articles/manage-article/:id?`}
    >
      <ManageArticle />
    </ProtectedRoute>

    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH_SPORT_ROOT_PATH}/blogCategories`}
    >
      <BlogCategories />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH_SPORT_ROOT_PATH}/blogCategories/manage-category/:id?`}
    >
      <ManageBlogCategory />
    </ProtectedRoute>

    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH_SPORT_ROOT_PATH}/video`}
    >
      <Video />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTSH_SPORT_ROOT_PATH}/video/manage-video/:id?`}
    >
      <ManageVideo />
    </ProtectedRoute>
  </Switch>
);

export { RtshSportRouter, RTSH_SPORT_ROOT_PATH, rtshSportRoutes };
