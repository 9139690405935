import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import Card from "antd/es/card";
import {Skeleton, Select as ASelect, Col} from "antd";
import Row from "antd/es/grid/row";
import { Form as FormikForm, Formik } from "formik";

import openNotification from "../common/components/Notification";
import Input from "../common/components/forms/TextInput";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import menus from "core/application/menus/menu";
import { commandRequest, queryRequest } from "../common/utilities";
import SaveButton from "../common/components/forms/SaveButton";
import CancelButton from "../common/components/forms/CancelButton";
import Select from "../common/components/forms/Select";

const formValidator = Yup.object({
  title: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  url: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
});

const ManageMenu = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [item, setItems] = useState();
  const [saveLoading, setSaveLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [availableMenuUrls, setAvailableMenuUrls] = useState();
  const [type, setType] = useState(0);
  const { menuId, id } = useParams();
  const pageTitle = id ? "Modifiko menunë" : "Krijo menu";
  const { Option } = ASelect;

  const updateSettings = async () => {
    setLoading(true);
    const result = await queryRequest(() => menus.getForSave(menuId, id));
    setItems(result);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updateSettings();
    })();
  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);
    values.menuId = menuId;
    values.type = parseInt(type);
    values.target = "_self";
    const result = await commandRequest(() => menus.save(values));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification("success","Operacioni","Ruajtja e menusë u krye me sukses.");
      history.push("/menus/");
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };
  return (
    <Skeleton loading={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={formValidator}
            onSubmit={onSubmit}>
            {props => (
              <FormikForm>
                <Row>
                  <Input  label="Titulli" name="title" type="text" placeholder="Titulli" />
                </Row>

                <Row gutter={8}>
                  <Col span={16}>
                    <Input label="Url" name="url" type="text" placeholder="Url" />
                  </Col>

                  <Col span={8}>
                    <Select
                        defaultValue={null}
                        style={{ width: 120 }}
                        label="List me url"
                        placeholder="Zgjidh Urls"
                        name="chooseUrl"
                        itemName="chooseUrl"
                        onChanged={(value) => {
                          if(value) {
                            props.setFieldValue("url", value);
                          }
                        }}>
                      <option value={null}>[Zgjidh Url]</option>
                      {item.availableInternalUrls.map(m => (
                          <option key={m.id} value={m.id}>
                            {m.name}
                          </option>
                      ))}
                    </Select>
                  </Col>
                </Row>

                <Row>
                  <Input label="Prioriteti" name="priority" type="number"placeholder="Prioriteti" />
                </Row>

                <Row>
                  {item.availableParents.length > 0 && (
                    <Select
                      defaultValue={item.parentId}
                      style={{ width: 120 }}
                      label="Zgjidh Prindin [Opsionale]"
                      placeholder="Zgjidh Prindin [Opsionale]"
                      name="parentId"
                      itemName="parentId">
                      <option value={null}>[S'ka prindër]</option>
                      {item.availableParents.map(m => (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </Select>
                  )}
                </Row>

                <Row>
                  <SaveButton loading={saveLoading} className="mr-16" />
                  <CancelButton />
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Skeleton>
  );
};

export default ManageMenu;
