import HttpClient from "./httpClient";
import config from "./config";
import authStore from "../auth/authStore";

class AdminHttpClient extends HttpClient {
  constructor() {
    super(config.apiEndpoint);
  }

  getHeaders() {
    return authStore.getToken()
      ? { Authorization: `Bearer ${authStore.getToken()}` }
      : {};
  }
}

export default AdminHttpClient;

const adminHttpClient = new AdminHttpClient();
export { adminHttpClient };
