import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const CancelButton = ({ title = "Anullo", backUrl = null }) => {
  let history = useHistory();

  const handleCancelClick = () => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <Button onClick={handleCancelClick}>{title}</Button>
    </>
  );
};

export default CancelButton;
