import {adminHttpClient} from "../commons/adminHttpClient";

export default {
  getAll: async () => adminHttpClient.get("/festivals"),
  getFestivalSongs: async festivalId =>
      adminHttpClient.get(`/festivals/songs/${festivalId}`),
  delete: async id => adminHttpClient.delete(`/festivals/${id}`),
  getForSave: async id => {
    if (id) {
      return adminHttpClient.get(`/festivals/forSave/${id}`);
    }
    return adminHttpClient.get(`/festivals/forSave`);
  },
  save: async article => adminHttpClient.post(`/festivals`, article)
};
