import React from "react";
import {Button, message, Modal} from "antd";
import {commandRequest} from "../../utilities";

const {confirm} = Modal;

const TableDeleteButton = ({
                             onDelete = () => {
                             },
                             onDeleted = () => {
                             },
                             onCancel = () => {
                             },
                             disableDeleted = false,
                             deleteTitle = "",
                             title = "Jeni të sigurt që doni ta fshini?",
                             content = "Ky veprim nuk mund të kthehet mbrapsht!",
                             className = "",
                             visible = true
                           }) => {
  return (
    visible && (
      <>
        <Button
            className={className}
            title={deleteTitle}
            disabled={disableDeleted}
            icon="delete"
            onClick={() => {
            confirm({
              title,
              content,
              onOk: async () => {
                const result = await commandRequest(() => onDelete());
                if (!result.errors) {
                  onDeleted();
                } else {
                  message.error(result.errors[0]);
                }
              },
              onCancel() {
                onCancel();
              }
            });
          }}
        ></Button>
      </>
    )
  );
};

export default TableDeleteButton;
