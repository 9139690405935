import React from "react";
import { Switch } from "react-router-dom";

import ManageProfileWrapper from "./ManageProfileWrapper";
import MyProfile from "./MyProfile";
import ChangePassword from "./ChangePassword";
import ProtectedRoute from "../common/configs/routes/protectedRoute";

const PROFILE_ROOT_PATH = "/my-profile";
export const CHANGE_PASSWORD_PATH = "change-password";
export const MY_PROFILE_PATH = "me";

const profileRoutes = [
  {
    icon: "pie-chart",
    key: "menu-2",
    path: `${PROFILE_ROOT_PATH}/`,
    name: "Artikuj"
  }
];

export const NaviationMapping = {
  MyProfile: 1,
  ChangePassword: 2
};

export const navigationPathMapping = new Map([
  ["my-profile", NaviationMapping.MyProfile],
  [CHANGE_PASSWORD_PATH, NaviationMapping.ChangePassword]
]);

const ProfileRouter = () => {
  return (
    <Switch>
      <ProtectedRoute path={`${PROFILE_ROOT_PATH}/${MY_PROFILE_PATH}`}>
        <ManageProfileWrapper>
          <MyProfile />
        </ManageProfileWrapper>
      </ProtectedRoute>
      <ProtectedRoute path={`${PROFILE_ROOT_PATH}/${CHANGE_PASSWORD_PATH}`}>
        <ManageProfileWrapper>
          <ChangePassword />
        </ManageProfileWrapper>
      </ProtectedRoute>
    </Switch>
  );
};

export { ProfileRouter, PROFILE_ROOT_PATH, profileRoutes };
