import React, {useContext} from "react";
import {categoriesMenu} from "./utils/menu";
import {Avatar, Col, Dropdown, Icon, Layout, Menu} from "antd";

import logo from "../../assets/images/rtsh-logo.png";
import activeRoute from "./utils/activeRoute";
import {useHistory, useLocation} from "react-router-dom";
import Row from "antd/es/grid/row";
import authStore from "core/application/auth/authStore";
import userStore from "core/application/users/userStore";
import {PROFILE_ROOT_PATH} from "../../../profile";
import {SITE_ROOT_PATH} from "../../../sites";

import "./Header.css";
import {AppContext} from "../../../AppContext";
import {USERS_ROOT_PATH} from "../../../users";

const {Header: AntHeader} = Layout;

const Header = ({selectedKey, onClick, links}) => {
  const {user} = useContext(AppContext);
  const current = activeRoute(links, useLocation().pathname);
  const history = useHistory();

  const logout = () => {
    authStore.setToken("");
    userStore.setUser(null);
    window.location = "/login";
  };

  const personalMenu = (
    <Menu>
      {user.canDoAnything && (
          <Menu.Item key="3" onClick={() => history.push(`${USERS_ROOT_PATH}/all`)}>
            <Icon type="user"/>
            <span>Perdoruesit</span>
          </Menu.Item>
      )}

      {user.canDoAnything && (
          <Menu.Item key="4" onClick={() => history.push(`${SITE_ROOT_PATH}/`)}>
            <Icon type="setting"/>
            <span>Konfigurime</span>
          </Menu.Item>
      )}

      <Menu.Item
          key="0"
          onClick={() => history.push(`${PROFILE_ROOT_PATH}/me`)}>
        <Icon type="profile"/>
        <span>Profili im</span>
      </Menu.Item>

      <Menu.Item key="2" onClick={() => logout()}>
        <Icon type="logout"/>
        <span>Log out</span>
      </Menu.Item>
    </Menu>
  );

  const getUserInitials = () => {
    if (user.name.indexOf(" ") === -1) return user.name;
    const firstNameLastName = user.name.split(" ");
    const firstName = firstNameLastName[0];
    const lastName = firstNameLastName[1];
    return firstName[0] + lastName[0];
  };

  let logoUrl = logo;
  if(user.site && user.site.logoUrl) {
    logoUrl = user.site.logoUrl;
  }

  return (
    <AntHeader className={"header dark"}>
      <div className="container">
        <Row>
          <Col span={3}>
            <div className="logo">
              <a href={"/"}>
                {" "}
                <img src={logoUrl} alt="logo" />
              </a>
            </div>
          </Col>
          <Col span={19}>
            <Menu
                className={"text-center"}
                mode="horizontal"
                onClick={e => {
                  onClick(e.key);
                }}
                defaultSelectedKeys={[current]}
                selectedKeys={[selectedKey || current]}>
              {categoriesMenu(links, "header", history)}
            </Menu>
          </Col>
          <Col span={2}>
            <div className="personal-dropdown">
              <Avatar className="header-user-icon mr-8">
                {getUserInitials()}
              </Avatar>

              <Dropdown overlay={personalMenu}>
                <a onClick={e => e.preventDefault()}>
                  {user.name.split(" ")[0]}
                </a>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
    </AntHeader>
  );
};

export default Header;
