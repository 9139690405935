import { adminHttpClient } from "../commons/adminHttpClient";

export default {
  getAll: async () => adminHttpClient.get("/publicationCategories"),
  delete: async id => adminHttpClient.delete(`/publicationCategories/${id}`),
  getForSave: async id => {
    if (id) {
      return adminHttpClient.get(`/publicationCategories/forSave/${id}`);
    }
    return adminHttpClient.get(`/publicationCategories/forSave`);
  },
  save: async category =>
    adminHttpClient.post(`/publicationCategories`, category)
};
