import React, {useState, useEffect, useContext} from "react";
import * as Yup from "yup";
import generateFriendlyUrl from "../../common/generateFriendlyUrl";
import openNotification from "../../common/components/Notification";
import { useHistory, useParams } from "react-router-dom";
import Card from "antd/es/card";
import { Form as FormikForm, Formik } from "formik";
import Input from "../../common/components/forms/TextInput";
import { Spin } from "antd";
import Row from "antd/es/grid/row";
import ValidationSummary from "../../common/components/forms/ValidationSummary";
import { commandRequest, queryRequest } from "../../common/utilities";
import SaveCancelButtons from "../../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../../siteUrlCreator";
import blogCategories from "core/application/articles/blogCategories";
import Select from "../../common/components/forms/Select";
import Uploader from "../../common/components/forms/Uploader";
import { hexCodeRegex } from "../../common/regexPatterns";
import {AppContext} from "../../AppContext";
import Switch from "../../common/components/forms/Switch";
import editProfile from "core/application/profile/editProfile";

const validationRules = {
  name: Yup.string().nullable().trim().required("Fushë e detyrueshme"),
  friendlyUrl: Yup.string().nullable().trim().required("Fushë e detyrueshme"),
  color: Yup.string().nullable().trim().matches(hexCodeRegex, "Kodi i ngjyrës i pavlefshëm")
}


const CategoryToOptionMapper = site => (
  <option key={site.id} value={site.id}>
    {site.name}
  </option>
);

const ManageBlogCategory = () => {
  let history = useHistory();
  const { user, site } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { id } = useParams();
  const pageTitle = id ? "Modifiko kategorinë" : "Krijo kategorinë";
  const isEditMode = !!id;
  const [parentCategoriesOptions, setParentCategoriesOptions] = useState([]);
  const [languages, setLanguages] = useState([]);

  if(site.showLanguages) {
    validationRules["languageId"] = Yup.string()
        .nullable()
        .trim()
        .required("Ju lutem zgjidhni një gjuhe");
  }

  const contactFormValidator = Yup.object(validationRules);

  const updateForm = async () => {
    setLoading(true);

    const item = await queryRequest(() => blogCategories.getForSave(id));
    if(item) {
      setParentCategoriesOptions(
        item.availableParents.map(CategoryToOptionMapper)
      );

      setLanguages(item.availableLanguages);
    }

    setItem(item);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updateForm();
    })();
  }, []);

  const updateParents = async (languageId) => {
    debugger;
    let parents = await queryRequest(() => blogCategories.getByLanguage(languageId));
    parents = parents.filter(c => c.id !== item.id)
    setParentCategoriesOptions(parents.map(CategoryToOptionMapper));
  }

  const onSubmit = async values => {
    setSaveLoading(true);
    const itemToSave = {
      ...values,
      priority: parseInt(values.priority, 10),
      languageIds: values.languageId ? [values.languageId] : []
    };

    const result = await commandRequest(() => blogCategories.save(itemToSave));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification("success", "Operacioni", "Ruajtja e kategorisë u bë me sukses.");
      history.push(getSiteUrl("/blogCategories"));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit}>
            {props => (
              <FormikForm>
                <Row>
                  <Input
                    label="Emri"
                    name="name"
                    type="text"
                    placeholder="Emri i kategorisë"
                    onBlur={e => {
                          if(!isEditMode) {
                            props.setFieldValue("friendlyUrl", generateFriendlyUrl(e.currentTarget.value));
                          }
                    }}
                  />
                </Row>

                <Row>
                  <Input label="Url Miqësore" name="friendlyUrl" type="text" placeholder="Url Miqësore"/>
                </Row>

                {site.showLanguages && (
                    <Row>
                      <Select defaultValue={item.languageId} label="Gjuha"
                              placeholder="Zgjidh gjuhen"
                              name="languageId"
                              itemName="languageId"
                              onChanged={async (value) => {
                                await updateParents(value);
                                setItem({
                                  ...item,
                                  parentId: null
                                });
                              }}>
                        <option value={null}>[Zgjidh gjuhen]</option>
                        {languages.map(lang => (
                            <option key={lang.id} value={lang.id}>
                              {lang.name}
                            </option>
                        ))}
                      </Select>
                    </Row>)
                }

                <Row>
                  <Switch
                      name="leading"
                      label="Kryesues"
                      fieldName="leading"
                      defaultChecked={item.leading}
                  />
                </Row>

                <Row>
                  <Input label="Prioriteti" name="priority" type="text" placeholder="Prioriteti"/>
                </Row>

                <Row>
                  <Uploader label="Foto(40 x 23) [Opsionale]" initialValue={item.photoId}
                    name="photoId"
                    resizeToWidth={40}
                    resizeToHeight={30}
                    fieldName="photoId"
                  />
                </Row>

                <Row>
                  {parentCategoriesOptions.length > 0 && (
                    <Select
                      defaultValue={item.parentId}
                      label="Prindi [Opsionale]"
                      placeholder="Zgjidh prindin"
                      name="parentId"
                      itemName="parentId">
                      <option value={null}>[Ska prindër]</option>
                      {parentCategoriesOptions}
                    </Select>
                  )}
                </Row>

                <Row>
                  <Input label="Ngjyra [Opsionale]" name="color" type="text" placeholder="#000000"/>
                </Row>

                <Row>
                  <SaveCancelButtons loading={saveLoading} />
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManageBlogCategory;
