import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

const AddButton = ({
  addUrl,
  title = "Shto",
  icon = "plus",
  visible = true
}) => {
  let history = useHistory();
  return (
    visible && (
      <Button
        icon={icon}
        type="primary"
        onClick={() => {
          history.push(addUrl);
        }}
      >
        {title}
      </Button>
    )
  );
};

export default AddButton;
