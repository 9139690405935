import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useHistory, useParams} from "react-router-dom";
import Card from "antd/es/card";
import {Form as FormikForm, Formik} from "formik";
import {Col, Spin} from "antd";
import Row from "antd/es/grid/row";
import openNotification from "../common/components/Notification";
import Input from "../common/components/forms/TextInput";
import Uploader from "../common/components/forms/Uploader";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import programs from "core/application/programs/programs";
import {commandRequest, queryRequest} from "../common/utilities";
import SaveCancelButtons from "../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../siteUrlCreator";

const contactFormValidator = Yup.object({
  horizontalPhotoId: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  verticalPhotoId: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  rating: Yup.number()
      .min(0)
    .max(5)
});

const ManageProgram = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { id } = useParams();
  const pageTitle = id ? "Modifiko programin" : "Krijo program";

  const updateForm = async () => {
    setLoading(true);

    const item = await queryRequest(() => programs.getForSave(id));
    setItem(item);

    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updateForm();
    })();
  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);

    const request = {
      title: item.title,
      friendlyUrl: item.friendlyUrl,
      content: item.content,
      horizontalPhotoId: values.horizontalPhotoId,
      verticalPhotoId: values.verticalPhotoId,
      rating: values.rating,
      id: item.id
    }

    const result = await commandRequest(() => programs.save(request));

    setSaveLoading(false);

    if (!result.errors) {
      openNotification("success", "Operacioni", "Ruajtja e programit u krye me sukses.");
      history.push(getSiteUrl("/programs"));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit}
          >
            {props => (
              <FormikForm>

                <Row gutter={[16]}>
                  <Col span={24}>
                    <Uploader
                      resizeToWidth={1280}
                      resizeToHeight={720}
                      label="Foto horizontale (1280 x 720)"
                      initialValue={item.horizontalPhotoId}
                      name="horizontalPhotoId"
                      fieldName="horizontalPhotoId"
                    />
                  </Col>
                </Row>

                <Row gutter={[16]}>
                  <Col span={24}>
                    <Uploader
                      resizeToWidth={650}
                      resizeToHeight={960}
                      label="Foto vertikale (650 x 960)"
                      initialValue={item.verticalPhotoId}
                      name="verticalPhotoId"
                      fieldName="verticalPhotoId"
                    />
                  </Col>
                </Row>

                <Row gutter={[16]}>
                  <Col span={24}>
                    <Input
                      label="Vlerësimi (1-5)"
                      name="rating"
                      type="number"
                      placeholder="Vlerësimi"
                    />
                  </Col>
                </Row>

                <Row gutter={[16]}>
                  <Col span={24}>
                    <SaveCancelButtons loading={saveLoading} />
                  </Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManageProgram;
