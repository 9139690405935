import React from "react";
import ReactDOM from "react-dom";

import "antd/dist/antd.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContext } from "./AppContext";
import userStore from "core/application/users/userStore";
import getCurrentSite from "./currentSite";

ReactDOM.render(
  <AppContext.Provider
    value={{
      user: userStore.getUser(),
      site: userStore.getUser() != null ? getCurrentSite() : null
    }}>
    <Router>
      <App />
    </Router>
  </AppContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
