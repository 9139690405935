import React, {useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Skeleton, Table, Button } from "antd";

import { queryRequest } from "../../common/utilities";
import AddButton from "../../common/components/tables/AddButton";
import getSiteUrl from "../../siteUrlCreator";
import { displayDateAndHour } from "../../common/displayUtilities";
import OrderedPaginationFilter from "core/application/commons/orderedPaginationFilter";
import PagedList from "../../common/models/pagedList";
import audioCategories from "core/application/audios/categories/audioCategories";
import EditDeleteButtons from "../../common/components/tables/EditDeleteButtons";
import {AppContext} from "../../AppContext";

const AudioCategories = () => {
    let history = useHistory();
    const {user} = useContext(AppContext);
    const [pagedList, setPagedList] = useState(new PagedList());
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState(
        new OrderedPaginationFilter({
            orderColumn: "created"
        })
    );

    const updatePagedList = async () => {
        setLoading(true);
        const result = await queryRequest(() => audioCategories.getAll(filter));
        setPagedList(result);
        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            await updatePagedList();
        })();
    }, [filter]);

    const onChange = (pagination, filters, sorter) => {
        const filter = new OrderedPaginationFilter({
            orderColumn: sorter.field,
            orderDescending: sorter.order !== "ascend",
            pageNumber: pagination.current
        });
        setFilter(filter);
    };

    const columns = [
        {
            title: "Emri",
            dataIndex: "name",
            sorter: true,
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Episode",
            dataIndex: "totalEpisodes",
            sorter: false,
            render: (value, record) => (
                <Button
                    onClick={() => {
                        history.push(getSiteUrl(`/podcasts/episodes/${record.id}`));
                    }}>
                    {`${value} Episod(e)`}
                </Button>
            )
        },
        {
            title: "Krijuar në",
            dataIndex: "created",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            render: value => displayDateAndHour(value)
        },
        {
            title: "Veprim",
            key: "action",
            render: (text, record) => {
                return user.canDoAnything && (<EditDeleteButtons
                    editUrl={getSiteUrl(`/podcasts/manage-podcast/${record.id}`)}
                    onOkDelete={() => audioCategories.delete(record.id)}
                    onDeleted={() => updatePagedList()}
                />)
            }
        }
    ];

    const columnToSort = columns.find(c => c.dataIndex === filter.orderColumn);
    columnToSort.defaultSortOrder = filter.orderDescending ? "descend" : "ascend";

    return (
        <>
            <Row>
                <Col span={16}>
                    <h4>Podcast</h4>
                </Col>
                <Col span={8}>
                    <div className={"text-right"}>
                        <AddButton visible={user.canDoAnything}
                                   addUrl={getSiteUrl(`/podcasts/manage-podcast`)} />
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={pagedList.items}
                onChange={onChange}
                loading={loading}
                pagination={{
                    total: pagedList.totalItemCount,
                    pageSize: pagedList.pageSize,
                    current: pagedList.pageNumber
                }}
            />
        </>
    );
};

export default AudioCategories;
