import {adminHttpClient} from "../commons/adminHttpClient";
import OrderedPaginationFilter from "../commons/orderedPaginationFilter";

export default {
  getAll: async (filter = new OrderedPaginationFilter()) => {
    if (!filter) {
      throw new Error("Filtri nuk duhet të jetë bosh.");
    }

    return adminHttpClient.get(
        `/users?pageNumber=${filter.pageNumber}&orderColumn=${filter.orderColumn}&orderDescending=${filter.orderDescending}`
    );
  },
  delete: async id => adminHttpClient.delete(`/users/${id}`),
  getForUpdate: async id => {
    return adminHttpClient.get(`/users/forUpdate/${id}`);
  },
  getForCreate: async id => {
    return adminHttpClient.get(`/users/forCreate`);
  },
  addUser: async user => adminHttpClient.post(`/users`, user),
  updateUser: async user => adminHttpClient.put(`/users`, user),
  changePasswordForUser: async request => adminHttpClient.put("/users/changePasswordForUser", request)
};
