import React, {useContext, useEffect, useState} from "react";
import {Col, Row, Table} from "antd";

import {queryRequest} from "../../common/utilities";
import EditDeleteButtons from "../../common/components/tables/EditDeleteButtons";
import AddButton from "../../common/components/tables/AddButton";
import getSiteUrl from "../../siteUrlCreator";
import blogCategories from "core/application/articles/blogCategories";
import {displayDateAndHour,} from "../../common/displayUtilities";
import {AppContext} from "../../AppContext";
import moment from "moment";
import CheckCircleFilled from "@ant-design/icons/lib/icons/CheckCircleFilled";

const BlogCategories = () => {
  const {user} = useContext(AppContext);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateCategories = async () => {
    setLoading(true);
    const items = await queryRequest(() => blogCategories.getAll());
    items.forEach(item => {
      item.children = item.children.length === 0 ? null : item.children;
    });
    setItems(items);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updateCategories();
    })();
  }, []);

  const columns = [
    {
      key: "name",
      title: "Emri",
      dataIndex: "name",
      sorter: (a, b) => a.length - b.length,
      sortDirections: ["descend", "ascend"]
    },
    {
      key: "priority",
      title: "Prioriteti",
      dataIndex: "priority",
      sorter: (a, b) => a.priority - b.priority,
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Kryesues",
      dataIndex: "leading",
      sorter: false,
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
          record.leading ? (<CheckCircleFilled/>) : ""
      )
    },
    {
      key: "created",
      title: "Krijuar në",
      dataIndex: "created",
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      sortDirections: ["descend", "ascend"],
      render: value => displayDateAndHour(value)
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => {
        return user.canDoAnything && (<EditDeleteButtons
            editUrl={getSiteUrl(`/blogCategories/manage-category/${record.id}`)}
            onOkDelete={() => blogCategories.delete(record.id)}
            onDeleted={() => updateCategories()}
            disableDeleted={record.hasChildren}
            deleteTitle={
              record.hasChildren ? "Nuk mund të fshihet sepse ka fëmijë." : ""
            }
        />)
      }
    }
  ];

  return (
    <>
      <Row>
        <Col span={16}>
          <h4>Kategoritë</h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton addUrl={getSiteUrl(`/blogCategories/manage-category`)} />
          </div>
        </Col>
      </Row>

      <Table
          rowKey={"id"}
          columns={columns}
          dataSource={items}
          loading={loading}
      />
    </>
  );
};

export default BlogCategories;
