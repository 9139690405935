const generateFriendlyUrl = title => {
    title = title.toLowerCase();
    title = replaceGreekLettersWithEnglish(title);

    title = title
        .replace(/[?!.,]+/gi, "")
        .replace(/[ë]+/gi, "e")
        .replace(/[ç]+/gi, "c")
        .replace(/[^a-z0-9]+/gi, "-")
        .replace(/^\s+|\s+$/g, '');

    return title;
}

const replaceGreekLettersWithEnglish = (str) => {
    const from = ["ου", "ΟΥ", "Ού", "ού", "αυ", "ΑΥ", "Αύ", "αύ", "ευ", "ΕΥ", "Εύ", "εύ", "α", "Α", "ά", "Ά", "β", "Β", "γ", "Γ", "δ", "Δ", "ε", "Ε", "έ", "Έ", "ζ", "Ζ", "η", "Η", "ή", "Ή", "θ", "Θ", "ι", "Ι", "ί", "Ί", "ϊ", "ΐ", "Ϊ", "κ", "Κ", "λ", "Λ", "μ", "Μ", "ν", "Ν", "ξ", "Ξ", "ο", "Ο", "ό", "Ό", "π", "Π", "ρ", "Ρ", "σ", "Σ", "ς", "τ", "Τ", "υ", "Υ", "ύ", "Ύ", "ϋ", "ΰ", "Ϋ", "φ", "Φ", "χ", "Χ", "ψ", "Ψ", "ω", "Ω", "ώ", "Ώ"];
    const to = ["ou", "ou", "ou", "ou", "au", "au", "au", "au", "eu", "eu", "eu", "eu", "a", "a", "a", "a", "b", "b", "g", "g", "d", "d", "e", "e", "e", "e", "z", "z", "i", "i", "i", "i", "th", "th", "i", "i", "i", "i", "i", "i", "i", "k", "k", "l", "l", "m", "m", "n", "n", "ks", "ks", "o", "o", "o", "o", "p", "p", "r", "r", "s", "s", "s", "t", "t", "y", "y", "y", "y", "y", "y", "y", "f", "f", "x", "x", "ps", "ps", "o", "o", "o", "o"];

    for (let i = 0; i < from.length; i++) {
        while (str.indexOf(from[i]) !== -1) {
            str = str.replace(from[i], to[i]);
        }
    }

    return str;
}

export default generateFriendlyUrl;
