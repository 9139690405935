import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Row, Skeleton } from "antd";
import Card from "antd/es/card";

import Input from "../common/components/forms/TextInput";
import editProfile from "core/application/profile/editProfile";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import { commandRequest, queryRequest } from "../common/utilities";
import openNotification from "../common/components/Notification";
import refreshUserInStore from "core/application/users/refreshUser";
import userStore from "core/application/users/userStore";

const MyProfile = () => {
  const [loading, setLoading] = useState(true);
  const [submiting, setSubmiting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [item, setItem] = useState(null);
  const user = userStore.getUser();

  const updateSiteOptions = async () => {
    setItem({
      name: user.name
    });
  };

  useEffect(() => {
    (async () => {
      await updateSiteOptions();
      setLoading(false);
    })();
  }, []);

  const onSubmit = async values => {
    setSubmiting(true);
    const result = await commandRequest(() => editProfile(values.name));
    setSubmiting(false);
    if (!result.errors) {
      openNotification(
        "success",
        "Operacioni",
        "Profili u modifikua me sukses!"
      );
      await refreshUserInStore();
    } else {
      setErrors(result.errors);
    }
  };

  return (
    <Skeleton loading={loading} active={true}>
      {item && (
        <div id="edit-profile">
          <Card title="Modifiko profilin">
            <ValidationSummary errors={errors} />
            <Formik
              initialValues={item}
              validationSchema={formValidator}
              onSubmit={onSubmit}
            >
              {formik => (
                <form>
                  <Row>
                    <Input
                      label="Emri"
                      name="name"
                      type="text"
                      placeholder="Ju lutemi vendosni emrin e ri."
                    />
                  </Row>

                  <Row>
                    <Button
                      type="primary"
                      loading={submiting}
                      disabled={!formValidator.isValidSync(formik.values)}
                      onClick={() => onSubmit(formik.values)}
                    >
                      Ruaj
                    </Button>
                  </Row>
                </form>
              )}
            </Formik>
          </Card>
        </div>
      )}
    </Skeleton>
  );
};

const formValidator = Yup.object({
  name: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme")
});

export default MyProfile;
