import { adminHttpClient } from "../commons/adminHttpClient";
import OrderedPaginationFilter from "../commons/orderedPaginationFilter";

export default {
  getAll: async (filter = new OrderedPaginationFilter()) => {
    if (!filter) {
      throw new Error("Filtri nuk duhet të jetë bosh.");
    }
    return adminHttpClient.get(
      `/reportMessages?pageNumber=${filter.pageNumber}&orderColumn=${filter.orderColumn}&&orderDescending=${filter.orderDescending}`
    );
  },
  getById: async id => {
    return adminHttpClient.get(`/reportMessages/${id}`);
  }
};
