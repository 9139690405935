import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import openNotification from "../common/components/Notification";
import {useHistory, useParams} from "react-router-dom";
import Card from "antd/es/card";
import {Skeleton} from "antd";
import Row from "antd/es/grid/row";
import {Form as FormikForm, Formik} from "formik";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import manageUsers from "core/application/manageUsers/manageUsers";
import {commandRequest, queryRequest} from "../common/utilities";
import SaveButton from "../common/components/forms/SaveButton";
import CancelButton from "../common/components/forms/CancelButton";
import {USERS_ROOT_PATH} from ".";
import PasswordInput from "../common/components/forms/PasswordInput";

const formValidator = Yup.object({
    newPassword: Yup.string().nullable().required("Fushë e detyrueshme"),
    passwordConfirmation: Yup.string()
        .nullable()
        .trim()
        .when("newPassword", (password, schema) => {
            return schema.test({
                test: passwordConfirmation =>
                    !!password && passwordConfirmation === password,
                message: "Fjalëkalimi nuk përputhet"
            });
        }),
});

const ChangePasswordForUser = () => {
    let history = useHistory();
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const {id} = useParams();

    const getUserInfo = async () => {
        setLoading(true);

        const item = await queryRequest(() => manageUsers.getForUpdate(id));
        setItem(item);

        setLoading(false);
    }

    useEffect(() => {
        (async () => {
            await getUserInfo();
        })();
    }, []);

    const onSubmit = async values => {
        setSaveLoading(true);

        const request = {
            userId: id,
            newPassword: values.newPassword
        };

        const result = await commandRequest(() => manageUsers.changePasswordForUser(request));
        setSaveLoading(false);

        if (!result.errors) {
            openNotification("success", "Operacioni", "Ndryshimi i fjalëkalimit u krye me sukses.");
            history.push(`${USERS_ROOT_PATH}/all`);
        } else {
            setErrors(result.errors);
        }
    };

    return (
        <Skeleton loading={loading}>
            {item && (
                <Card title={`Ndrysho fjalëkalimin për: ${item.name}`}>
                    <ValidationSummary errors={errors}/>
                    <Formik
                        initialValues={item}
                        validationSchema={formValidator}
                        onSubmit={onSubmit}>
                        {formik => (
                            <FormikForm>

                                <Row>
                                    <PasswordInput label="Fjalëkalimi" name="newPassword" type="password"
                                                   placeholder="Ju lutemi vendosni fjalëkalimin"/>
                                </Row>

                                <Row>
                                    <PasswordInput label="Përsërit Fjalëkalimin e ri" name="passwordConfirmation"
                                                   type="password"
                                                   placeholder="Ju lutemi përsërisni fjalëkalimin e ri."/>
                                </Row>

                                <Row>
                                    <SaveButton loading={saveLoading}
                                                className="mr-16"
                                                disabled={!formValidator.isValidSync(formik.values)}
                                    />
                                    <CancelButton/>
                                </Row>
                            </FormikForm>
                        )}
                    </Formik>
                </Card>
            )}
        </Skeleton>
    );
};

export default ChangePasswordForUser;
