import React, {useContext, useEffect, useState} from "react";
import {Col, DatePicker as AntDatepicker, Row, Select, Table} from "antd";

import programs from "core/application/programs/programs";
import channels from "core/application/channels/channels";
import {queryRequest} from "../common/utilities";
import TableEditButton from "../common/components/tables/TableEditButton";
import {displayDateAndHour} from "../common/displayUtilities";
import {AppContext} from "../AppContext";
import SearchInput from "../articles/ArticleSearch";
import openNotification from "../common/components/Notification";
import ProgramFilter from "core/application/programs/ProgramFilter";
import idGenerator from "core/application/commons/idGenerator";
import moment from "moment";

const Programs = () => {
    const {user} = useContext(AppContext);
    const [pagedList, setPagedList] = useState({items: []});
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(
        new ProgramFilter({
            orderColumn: "startDate",
            orderDescending: true,
            pageNumber: 1,
            date: moment().toDate()
        })
    );
    const [channelItems, setChannelItems] = useState([]);

    const updateTable = async () => {
        setLoading(true);

        const pagedItems = await queryRequest(() => programs.getAll(filter));
        pagedItems.items.forEach(item => {
            item.key = idGenerator.uuidv4()
        })
        setPagedList(pagedItems);

        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            setChannelItems(await queryRequest(() => channels.getAll()));
        })();
    }, [])

    useEffect(() => {
        (async () => {
            await updateTable();
        })();
    }, [filter]);

    const onPaginationChange = (pagination, filters, sorter) => {
        const newFilter = new ProgramFilter({
            ...filter,
            orderColumn: sorter.field,
            orderDescending: sorter.order !== "ascend",
            pageNumber: pagination.current
        });

        setFilter(newFilter);
    };

    const onSearch = (searchValue) => {
        if (searchValue && searchValue.length < 3) {
            openNotification("info", "Vendosni të paktën 3 karaktere për të kërkuar");
            return;
        }

        const newFilter = new ProgramFilter({
            ...filter,
            searchValue,
            pageNumber: 1
        });

        setFilter(newFilter);
    }

    const onChannelChange = (value) => {
        const newFilter = new ProgramFilter({
            ...filter,
            pageNumber: 1,
            channelFriendlyUrl: value
        });

        setFilter(newFilter);
    }

    const onDateChange = (value) => {
        const newFilter = new ProgramFilter({
            ...filter,
            pageNumber: 1,
            date: value.toDate()
        });

        setFilter(newFilter);
    }

    const columns = [
        {
            title: "Titulli",
            dataIndex: "title",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            width: "50%"
        },
        {
            title: "Data",
            dataIndex: "startDate",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            render: value => displayDateAndHour(value)
        },
        {
            title: "Gjatësia",
            dataIndex: "length",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            render: value => `${value} min`
        },
        {
            title: "Kanali",
            dataIndex: "channel",
            render: value => value.name
        },
        {
            title: "Veprim",
            key: "action",
            render: (text, record) => (
                <>
                    <TableEditButton
                        className="mr-16"
                        editUrl={`/programs/manage-program/${record.id}`}
                        visible={user.canSaveArticle}
                    />
                </>
            )
        }
    ];

    columns.find(
        c => c.dataIndex === filter.orderColumn
    ).defaultSortOrder = filter.orderDescending ? "descend" : "ascend";

    return (
        <>
            <Row gutter={16}>
                <Col span={8}>
                    <h4>Programet</h4>
                </Col>
                <Col span={4}>
                    <AntDatepicker onChange={onDateChange}/>
                </Col>
                <Col span={4}>
                    <Select defaultValue={null} onChange={onChannelChange}>
                        <option value={null}>[Zgjidh kanalin]</option>
                        {channelItems.map(channel => (
                            <option key={channel.friendlyUrl} value={channel.friendlyUrl}>
                                {channel.name}
                            </option>
                        ))}
                    </Select>
                </Col>
                <Col span={8}>
                    <SearchInput onSearch={onSearch}/>
                </Col>
            </Row>
            <Table
                rowKey={record => `${record.key}`}
                columns={columns}
                dataSource={pagedList.items}
                onChange={onPaginationChange}
                loading={loading}
                pagination={{
                    total: pagedList.totalItemCount,
                    pageSize: pagedList.pageSize,
                    current: pagedList.pageNumber
                }}
            />
        </>
    );
};

export default Programs;
