import OrderedPaginationFilter from "../commons/orderedPaginationFilter";

class ProgramFilter extends OrderedPaginationFilter {
    constructor({
                    pageNumber = 1,
                    orderColumn = "created",
                    orderDescending = true,
                    searchValue = "",
                    channelFriendlyUrl = "",
                    date = null
                } = {}) {
        super({
            pageNumber,
            orderColumn,
            orderDescending,
            searchValue
        });
        this.channelFriendlyUrl = channelFriendlyUrl;
        this.date = date;
    }
}

export default ProgramFilter;