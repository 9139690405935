import React from "react";
import {Button} from "antd";
import {useHistory} from "react-router-dom";
import getSiteUrl from "../../../siteUrlCreator";

const TableEditButton = ({editUrl, className, visible = true, appendEditUrlAfterSiteUrl = true}) => {
    const history = useHistory();
    const resultUrl = appendEditUrlAfterSiteUrl ? getSiteUrl(editUrl) : editUrl;
    return (
        visible && (
            <>
                <Button
                    className={className}
                    icon="edit"
                    onClick={() => {
                        history.push(resultUrl);
                    }}>
                </Button>
      </>
    )
  );
};

export default TableEditButton;
