import { Button } from "antd";
import React from "react";

const PublishButton = ({
  loading = false,
  title = "Publiko",
  icon = "check",
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button
      icon={icon}
      type="success"
      htmlType="submit"
      onClick={onClick}
      className={className}
      loading={loading}
    >
      {title}
    </Button>
  );
};
export default PublishButton;
