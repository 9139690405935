import React, {useContext, useEffect, useState} from "react";
import * as Yup from "yup";
import Row from "antd/es/grid/row";
import Card from "antd/es/card";
import {Skeleton} from "antd";
import {useHistory, useParams} from "react-router-dom";

import generateFriendlyUrl from "../common/generateFriendlyUrl";
import {Form as FormikForm, Formik} from "formik";
import Input from "../common/components/forms/TextInput";
import Uploader from "../common/components/forms/Uploader";
import Editor from "../common/components/forms/Editor";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import articlesApi from "core/application/articles/articles";
import {commandRequest, queryRequest} from "../common/utilities";
import getSiteUrl from "../siteUrlCreator";
import Select from "../common/components/forms/Select";
import CancelButton from "../common/components/forms/CancelButton";
import SaveButton from "../common/components/forms/SaveButton";
import PublishButton from "../common/components/forms/PublishButton";
import {AppContext} from "../AppContext";
import Switch from "../common/components/forms/Switch";
import blogCategoriesApi from "core/application/articles/blogCategories";
import notifications from "../common/components/notifications/notifications";

const validationRules = {
  title: Yup.string()
      .nullable()
      .trim()
      .required("Fushë e detyrueshme"),
  friendlyUrl: Yup.string()
      .nullable()
      .trim()
      .required("Fushë e detyrueshme"),
  content: Yup.string()
      .nullable()
      .trim()
      .required("Fushë e detyrueshme"),
  photoId: Yup.string()
      .nullable()
      .trim()
      .required("Fushë e detyrueshme"),
  categoryIds: Yup.string()
      .nullable()
      .trim()
      .required("Ju lutem zgjidhni një kategori"),
}

const ManageArticle = () => {
  let history = useHistory();
  const { user, site } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [saveAsDraftLoading, setAsSaveDraftLoading] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const { id } = useParams();
  const pageTitle = id ? "Modifiko artikullin" : "Krijo artikull";
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [languages, setLanguages] = useState([]);

  if(site.showLanguages) {
    validationRules["languageId"] = Yup.string()
        .nullable()
        .trim()
        .required("Ju lutem zgjidhni një gjuhe");
  }

  const contactFormValidator = Yup.object(validationRules);

  const updateForm = async () => {
    setLoading(true);

    const article = await queryRequest(() => articlesApi.getForSave(id));
    setCategoriesOptions(article.availableCategories);
    setLanguages(article.availableLanguages);
    setItem(article);

    setLoading(false);
  };

  const updateCategories = async (languageId) => {
    const categories = await queryRequest(() => blogCategoriesApi.getByLanguage(languageId));
    setCategoriesOptions(categories);
  }

  useEffect(() => {
    (async () => {
      await updateForm();
      setLoading(false);
    })();
  }, []);

  const showButtonLoader = () => {
    if (saveAsDraft) {
      setAsSaveDraftLoading(true);
    } else {
      setPublishLoading(true);
    }
  };

  const hideButtonLoader = () => {
    if (saveAsDraft) {
      setAsSaveDraftLoading(false);
    } else {
      setPublishLoading(false);
    }
  };

  const onSubmit = async values => {
    showButtonLoader();

    const request = {
      ...values,
      id
    }
    const result = await commandRequest(() =>
      saveAsDraft ? articlesApi.saveAsDraft(request) : articlesApi.publish(request)
    );

    hideButtonLoader();

    if (!result.errors) {
      notifications.showSuccess()
      history.push(getSiteUrl("/articles"));
    } else {
      setSaveAsDraft(false);
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        <Skeleton loading={loading}>
          {item && (
              <Formik
                  enableReinitialize={true}
                  initialValues={item}
                  validationSchema={contactFormValidator}
                  onSubmit={onSubmit}>
                {props => (
                    <FormikForm>
                      <Row>
                        <Input
                            label="Titulli"
                            name="title"
                            type="text"
                            placeholder="Titulli i artikullit"
                            onBlur={e => {
                              props.setFieldValue(
                                  "friendlyUrl",
                                  generateFriendlyUrl(e.currentTarget.value)
                              );
                            }}
                        />
                      </Row>

                      <Row>
                        <Input
                            label="Url miqësore"
                            name="friendlyUrl"
                            type="text"
                            placeholder="Url miqësore"
                        />
                      </Row>

                      <Row>
                        <Uploader
                            label="Foto (640 x 426)"
                            initialValue={item.photoId}
                            name="photoId"
                            resizeToWidth={640}
                            resizeToHeight={426}
                            fieldName="photoId"
                            viewImageLibrary={true}
                        />
                      </Row>

                      <Row>
                        <Input
                            label="Diçitura e fotos"
                            name="photoCaption"
                            type="text"
                            placeholder="Diçitura e fotos"
                        />
                      </Row>

                      <Row>
                        <Editor
                            label="Përmbajtja"
                            initialValue={item.content}
                            name="content"
                            fieldName="content"
                        />
                      </Row>

                      <Row>
                        <Switch
                            name="leading"
                            label="Kryesues"
                            fieldName="leading"
                            defaultChecked={item.leading}
                        />
                      </Row>

                      {site.showLanguages && (
                          <Row>
                            <Select defaultValue={item.languageId} label="Gjuha"
                                    placeholder="Zgjidh gjuhen"
                                    name="languageId"
                                    itemName="languageId"
                                    onChanged={async (value) => {
                                      await updateCategories(value);
                                    }}>
                              <option value={null}>[Zgjidh gjuhen]</option>
                              {languages.map(lang => (
                                  <option key={lang.id} value={lang.id}>
                                    {lang.name}
                                  </option>
                              ))}
                            </Select>
                          </Row>)
                      }

                      <Row>
                        <Select
                            defaultValue={item.categoryIds}
                            mode="multiple"
                            label="Kategoria"
                            placeholder="Zgjidh kategorinë"
                            name="categoryIds"
                            itemName="categoryIds">
                          <option value={null}>[Zgjidh kategori]</option>
                          {categoriesOptions.map(category => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                          ))}
                        </Select>
                      </Row>

                      <Row>
                        <Select
                            defaultValue={item.tags}
                            mode="tags"
                            label="Tags"
                            placeholder=""
                            name="tags"
                            itemName="tags">
                          {item.tags.map(tag => (
                              <option key={tag} value={tag}>
                                {tag}
                              </option>
                          ))}
                        </Select>
                      </Row>

                      <Row>
                        {user.canPublishArticle && (
                            <PublishButton
                                loading={publishLoading}
                                className="mr-16"
                                onClick={() => {
                                  setSaveAsDraft(false);
                                }}
                            />
                        )}

                        {user.canSaveArticle && (
                            <SaveButton
                                loading={saveAsDraftLoading}
                                className="mr-16"
                                onClick={() => {
                                  setSaveAsDraft(true);
                                }}
                            />
                        )}
                        <CancelButton/>
                      </Row>
                    </FormikForm>
                )}
              </Formik>
          )}
        </Skeleton>
      </Card>
  );
};

export default ManageArticle;
