import React from "react";
import {Switch} from "react-router-dom";

import AddUser from "./AddUser";
import ViewUsers from "./ViewUsers";
import ProtectedRoute from "../common/configs/routes/protectedRoute";
import UpdateUser from "./UpdateUser";
import ChangePasswordForUser from "./ChangePasswordForUser";

const USERS_ROOT_PATH = "/users";
export const ADD_USER_PATH = "add";

const mainRoute = {
    icon: "pie-chart",
    key: "menu-10",
    path: `${USERS_ROOT_PATH}/all`,
    name: "Përdoruesit"
};

const UsersRouter = () => {
    return (
        <Switch>
            <ProtectedRoute exact path={`${USERS_ROOT_PATH}/all`}>
                <ViewUsers/>
            </ProtectedRoute>
            <ProtectedRoute exact path={`${USERS_ROOT_PATH}/${ADD_USER_PATH}`}>
                <AddUser/>
            </ProtectedRoute>
            <ProtectedRoute exact path={`${USERS_ROOT_PATH}/manage-user/:id`}>
                <UpdateUser/>
            </ProtectedRoute>
            <ProtectedRoute exact path={`${USERS_ROOT_PATH}/change-password-for-user/:id`}>
                <ChangePasswordForUser />
            </ProtectedRoute>
        </Switch>
    );
};

export {UsersRouter, USERS_ROOT_PATH, mainRoute};
