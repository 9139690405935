import { adminHttpClient } from "../commons/adminHttpClient";

export default {
  getMenus: async () => adminHttpClient.get("/Menu"),

  getAll: async id => {
    return adminHttpClient.get(`/menu/${id}`);
  },
  getForSave: async (menuId, id) => {
    if (id) {
      return adminHttpClient.get(`/menu/forSave/${menuId}/${id}`);
    }
    return adminHttpClient.get(`/menu/forSave/${menuId}`);
  },
  delete: async id => adminHttpClient.delete(`/menu/${id}`),
  save: async values => adminHttpClient.post(`/menu`, values)
};
