import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import Card from "antd/es/card";
import { Skeleton, Select as ASelect } from "antd";
import Row from "antd/es/grid/row";

import openNotification from "../common/components/Notification";
import Input from "../common/components/forms/TextInput";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import pages from "core/application/pages/page";
import { commandRequest, queryRequest } from "../common/utilities";
import SaveButton from "../common/components/forms/SaveButton";
import CancelButton from "../common/components/forms/CancelButton";
import { Form as FormikForm, Formik } from "formik";
import Editor from "../common/components/forms/Editor";
import generateFriendlyUrl from "../common/generateFriendlyUrl";

const formValidator = Yup.object({
  title: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  friendlyUrl: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme")
});

const ManagePage = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [item, setItems] = useState();
  const [saveLoading, setSaveLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { id } = useParams();
  const pageTitle = id ? "Modifiko faqen statike" : "Krijo faqe statike";
  const { Option } = ASelect;

  const updateSettings = async () => {
    setLoading(true);
    const result = await queryRequest(() => pages.getForSave(id));
    setItems(result);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updateSettings();
    })();
  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);
    const result = await commandRequest(() => pages.save(values));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification(
        "success",
        "Operacioni",
        "Ruajtja e faqes statike u krye me sukses."
      );
      history.push("/page/");
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };
  return (
    <Skeleton loading={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={formValidator}
            onSubmit={onSubmit}
          >
            {props => (
              <FormikForm>
                <Row>
                  <Input
                    label="Titulli"
                    name="title"
                    type="text"
                    placeholder="Titulli"
                    onBlur={e => {
                      props.setFieldValue(
                        "friendlyUrl",
                        generateFriendlyUrl(e.currentTarget.value)
                      );
                    }}
                  />
                </Row>

                <Row>
                  <Input
                    label="Url miqësore"
                    name="friendlyUrl"
                    type="text"
                    placeholder="Url miqësore"
                  />
                </Row>
                <Row>
                  <Editor
                    label="Përmbajtja"
                    initialValue={item.content}
                    name="content"
                    fieldName="content"
                  />
                </Row>
                <Row>
                  <SaveButton loading={saveLoading} className="mr-16" />
                  <CancelButton />
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Skeleton>
  );
};

export default ManagePage;
