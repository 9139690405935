import { notification as antNotification } from "antd";

const notification = (type, message, description) => {
  antNotification[type]({
    message: message,
    description: description
  });
};

export default notification;
