import React, { useContext, useState, useEffect } from "react";
import { Row, Table, Col, Spin } from "antd";
import moment from "moment";

import { queryRequest } from "../../common/utilities";
import TableEditButton from "../../common/components/tables/TableEditButton";
import TableDeleteButton from "../../common/components/tables/TableDeleteButton";
import AddButton from "../../common/components/tables/AddButton";
import getSiteUrl from "../../siteUrlCreator";
import eventsCategories from "core/application/events/eventsCategories";
import {
  displayDateAndHour,
  displayNamedItem
} from "../../common/displayUtilities";
import { AppContext } from "../../AppContext";

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

const EventsCategories = () => {
  const { user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateTable = async () => {
    setLoading(true);

    const items = await queryRequest(() => eventsCategories.getAll());
    setData(items);

    setLoading(false);
  };

  useEffect(() => {
    updateTable();
  }, []);

  const columns = [
    {
      title: "Emri",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Prioriteti",
      dataIndex: "priority",
      sorter: (a, b) => a.priority - b.priority,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Prindi",
      dataIndex: "parent",
      render: value => displayNamedItem(value)
    },
    {
      title: "Krijuar në",
      dataIndex: "created",
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      sortDirections: ["descend", "ascend"],
      render: value => displayDateAndHour(value)
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => (
        <>
          <TableEditButton
            className="mr-16"
            editUrl={`/eventsCategories/manage-events-category/${record.id}`}
            visible={user.canSaveArticle}
          />
          <TableDeleteButton
            onDelete={() => eventsCategories.delete(record.id)}
            onDeleted={() => updateTable()}
            // disableDeleted={record.hasChildren}
            // deleteTitle={
            //   record.hasChildren ? "Nuk mund të fshihet sepse ka fëmijë." : ""
            // }
            visible={user.canDoAnything}
          />
        </>
      )
    }
  ];

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={16}>
          <h4>Kategoritë e Eventeve</h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton
              addUrl={getSiteUrl(`/eventsCategories/manage-events-category`)}
            />
          </div>
        </Col>
      </Row>
      <Table columns={columns} dataSource={data} onChange={onChange} />
    </Spin>
  );
};

export default EventsCategories;
