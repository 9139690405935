import {Switch} from "react-router-dom";
import React from "react";
import ProtectedRoute from "../common/configs/routes/protectedRoute";
import Channels from "../channels/Channels";
import ManageChannel from "../channels/ManageChannel";
import Banners from "../banners/Banners";
import ManageBanner from "../banners/ManageBanner";
import BroadcastCategories from "../broadcastCategories/BroadcastCategories";
import ManageBroadcastCategories from "../broadcastCategories/ManageBroadcastCategories";
import Programs from "../programs/Programs";
import ManageProgram from "../programs/ManageProgram";
import getLoginUser from "core/application/users/getLoginUser";
import Permission from "core/domain/users/Permission";
import Articles from "../articles/Articles";
import ManageArticle from "../articles/ManageArticle";
import Video from "../videos/Video";
import ManageVideo from "../videos/ManageVideo";
import AudioCategories from "../audios/categories/AudioCategories";
import ManageAudioCategory from "../audios/categories/ManageAudioCategory";
import Audio from "../audios/Audio";
import ManageAudio from "../audios/ManageAudio";

const RADIO_ROOT_PATH = "/radio";

const user = getLoginUser();

const radioRoutes = [];

if (user && user.canDoAnything) {
    radioRoutes.push(
        {
            icon: "pie-chart",
            key: "menu-1",
            path: `${RADIO_ROOT_PATH}/articles`,
            name: "Artikuj"
        },
        {
            icon: "pie-chart",
            key: "menu-4",
            path: `${RADIO_ROOT_PATH}/podcasts`,
            name: "Podcast"
        },
        {
            icon: "pie-chart",
            key: "menu-8",
            path: `${RADIO_ROOT_PATH}/video`,
            name: "Video"
        },
        {
            icon: "pie-chart",
            key: "menu-9",
            path: `${RADIO_ROOT_PATH}/programs`,
            name: "Programe",
            children: [
                {
                    icon: "pie-chart",
                    key: "menu-10",
                    path: `${RADIO_ROOT_PATH}/programs`,
                    name: "Të gjithë programet"
                },
                {
                    icon: "pie-chart",
                    key: "menu-11",
                    path: `${RADIO_ROOT_PATH}/broadcastCategories`,
                    name: "Kategoritë"
                }
            ]
        },
        {
            icon: "pie-chart",
            key: "menu-7",
            path: `${RADIO_ROOT_PATH}/channels`,
            name: "Kanale"
        }
    );
}

const RadioRouter = () => (
    <Switch>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/articles`}>
            <Articles/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/articles/manage-article/:id?`}>
            <ManageArticle/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/banners`}>
            <Banners/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/banners/:bannerId/manage-banner-item/:id?`}>
            <ManageBanner/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/channels`}>
            <Channels/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/channels/manage-channel/:id?`}>
            <ManageChannel/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/video`}>
            <Video/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/video/manage-video/:id?`}>
            <ManageVideo/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/podcasts`}>
            <AudioCategories/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/podcasts/manage-podcast/:id?`}>
            <ManageAudioCategory/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/podcasts/episodes/:podcastId`}>
            <Audio />
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/podcasts/episodes/:podcastId/manage-episode/:id?`}>
            <ManageAudio />
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/broadcastCategories`}>
            <BroadcastCategories/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/broadcastCategories/manage-broadcast-category/:id?`}>
            <ManageBroadcastCategories/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/programs`}>
            <Programs/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${RADIO_ROOT_PATH}/programs/manage-program/:id?`}>
            <ManageProgram/>
        </ProtectedRoute>
    </Switch>
);

export {RadioRouter, RADIO_ROOT_PATH, radioRoutes};
