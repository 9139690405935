import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import { Col, Row, Skeleton, Table, Button } from "antd";

import audio from "core/application/audios/audio";
import { queryRequest } from "../common/utilities";
import AddButton from "../common/components/tables/AddButton";
import getSiteUrl from "../siteUrlCreator";
import { displayDateAndHour } from "../common/displayUtilities";

import PagedList from "../common/models/pagedList";
import audioFilter from "core/application/audios/audioFilter";
import audioCategories from "core/application/audios/categories/audioCategories";
import EditDeleteButtons from "../common/components/tables/EditDeleteButtons";
import {AppContext} from "../AppContext";

const Audio = () => {
  const {user} = useContext(AppContext);
  const {podcastId} = useParams();
  const [pagedList, setPagedList] = useState(new PagedList());
  const [podcast, setPodcast] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(
    new audioFilter({
      orderColumn: "created",
      categoryId: podcastId
    })
  );

  const updateEpisodesPagedList = async () => {
    setLoading(true);

    setPagedList(await queryRequest(() => audio.getAll(filter)));
    setPodcast(await queryRequest(() => audioCategories.getById(podcastId)));

    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updateEpisodesPagedList();
    })();
  }, [filter]);

  const onChange = (pagination, filters, sorter) => {
    const articleFilter = new audioFilter({
      orderColumn: sorter.field,
      orderDescending: sorter.order !== "ascend",
      pageNumber: pagination.current,
      categoryId: podcastId
    });
    setFilter(articleFilter);
  };

  const columns = [
    {
      title: "Emri",
      dataIndex: "name",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: (text, record) => <a className={"dark"}>{text}</a>
    },
    {
      title: "Krijuar në",
      dataIndex: "created",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: value => displayDateAndHour(value)
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) => {
        return user.canDoAnything && (<EditDeleteButtons
            editUrl={getSiteUrl(`/podcasts/episodes/${podcastId}/manage-episode/${record.id}`)}
            onOkDelete={() => audio.delete(record.id)}
            onDeleted={() => updateEpisodesPagedList()}
        />)
      }
    }
  ];

  const columnToSort = columns.find(c => c.dataIndex === filter.orderColumn);
  columnToSort.defaultSortOrder = filter.orderDescending ? "descend" : "ascend";

  return (
        <>
          {podcast && (
              <>
                <Row>
                  <Col span={16}>
                    <h4>Episodet per '{podcast.name}'</h4>
                  </Col>
                  <Col span={8}>
                    <div className={"text-right"}>
                      <AddButton addUrl={getSiteUrl(`/podcasts/episodes/${podcastId}/manage-episode`)} />
                    </div>
                  </Col>
                </Row>

                <Table
                  columns={columns}
                  dataSource={pagedList.items}
                  onChange={onChange}
                  loading={loading}
                  pagination={{
                    total: pagedList.totalItemCount,
                    pageSize: pagedList.pageSize,
                    current: pagedList.pageNumber
                  }}
                />
          </>)}
        </>
  );
};

export default Audio;
