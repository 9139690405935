import React, {useContext, useEffect, useState} from "react";
import loadable from "@loadable/component";
import {Layout} from "antd";

import "./App.css";
import {options} from "./common/configs/routes";
import {Route, Switch, useLocation} from "react-router-dom";
import Header from "./common/components/navigation/Header";
import ProtectedRoute from "./common/configs/routes/protectedRoute";
import {FESTIVAL_ROOT_PATH, FestivalRouter} from "./festivals";
import getCurrentSite from "./currentSite";
import {RTSH_SPORT_ROOT_PATH, RtshSportRouter} from "./rtshSport";
import {RTSH_ROOT_PATH, RtshRouter} from "./rtsh";
import {RTSH24_ROOT_PATH, Rtsh24Router} from "./rtsh24";
import {ORCHESTRA_ROOT_PATH, OrchestraRouter} from "./orchestra";
import {TV_ROOT_PATH, TvRouter} from "./tv";
import {RTI_ROOT_PATH, RtiRouter} from "./rti";
import {RADIO_ROOT_PATH, RadioRouter} from "./radio";
import {PROFILE_ROOT_PATH, ProfileRouter} from "./profile";
import {USERS_ROOT_PATH, UsersRouter} from "./users";
import {SITE_ROOT_PATH, SiteRouter} from "./sites/index";
import {MenuRouter, MENUS_ROOT_PATH} from "./menus";
import {PAGE_ROOT_PATH, PageRouter} from "./pages";
import {AppContext} from "./AppContext";
import refreshUserInStore from "core/application/users/refreshUser";

const PageNotFound = loadable(() => import("./errors/PageNotFound"), options);
const Error500Page = loadable(() => import("./errors/500Page"), options);
const Error401Page = loadable(() => import("./errors/401Page"), options);

const LoginForm = loadable(() => import("./login/LoginForm"));
const {Content} = Layout;

const App = () => {
  const {user} = useContext(AppContext);
  const [selectedKey, setKey] = useState(undefined);
  const onClick = key => {
    setKey(key);
  };

  useEffect(() => {
    (async () => {
      if(user) {
        await refreshUserInStore();
      }
    })();
  }, []);

  const headerRoutes = user ? getCurrentSite().ROUTES : [];

  const currentPath = useLocation().pathname;
  if (currentPath === "/") {
    if (!user) {
      window.location = "/login";
      return;
    }

    if (headerRoutes.length > 0) {
      window.location = headerRoutes[0].path;
    } else {
      window.location = "/login";
    }
    return;
  }
  const isLoginPage = currentPath === "/login";
  const layoutClasses = isLoginPage ? "login-page" : "";
  return (
    <>
      {!isLoginPage && user && (
        <Layout>
          <Header
            selectedKey={selectedKey}
            onClick={onClick}
            links={headerRoutes}
          />
        </Layout>
      )}
      <Layout className={layoutClasses}>
        <Content>
          <Switch>
            <Route exact path="/login">
              <LoginForm />
            </Route>

            <ProtectedRoute path={`${FESTIVAL_ROOT_PATH}/*`}>
              <FestivalRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${RTSH_SPORT_ROOT_PATH}/*`}>
              <RtshSportRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${RTSH_ROOT_PATH}/*`}>
              <RtshRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${RTSH24_ROOT_PATH}/*`}>
              <Rtsh24Router />
            </ProtectedRoute>

            <ProtectedRoute path={`${TV_ROOT_PATH}/*`}>
              <TvRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${RTI_ROOT_PATH}/*`}>
              <RtiRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${RADIO_ROOT_PATH}/*`}>
              <RadioRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${ORCHESTRA_ROOT_PATH}/*`}>
              <OrchestraRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${USERS_ROOT_PATH}/*`}>
              <UsersRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${PROFILE_ROOT_PATH}/*`}>
              <ProfileRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${SITE_ROOT_PATH}/*`}>
              <SiteRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${MENUS_ROOT_PATH}/*`}>
              <MenuRouter />
            </ProtectedRoute>

            <ProtectedRoute path={`${PAGE_ROOT_PATH}/*`}>
              <PageRouter />
            </ProtectedRoute>

            <Route path="/error500-page">
              <Error500Page />
            </Route>

            <Route path="/unauthorized-page">
              <Error401Page />
            </Route>

            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </>
  );
};

export default App;
