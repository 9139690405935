import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button, Row, Skeleton } from "antd";
import Card from "antd/es/card";

import PasswordInput from "../common/components/forms/PasswordInput";
import changePassword from "core/application/profile/changePassword";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import { commandRequest } from "../common/utilities";
import openNotification from "../common/components/Notification";
import refreshUserInStore from "core/application/users/refreshUser";

const ChangePassword = () => {
  const [loading, setLoading] = useState(true);
  const [submiting, setSubmiting] = useState(false);
  const [errors, setErrors] = useState([]);

  const [item, setItem] = useState(null);

  const updateSiteOptions = async () => {
    setItem({
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: ""
    });
  };

  useEffect(() => {
    (async () => {
      await updateSiteOptions();
      setLoading(false);
    })();
  }, []);

  const onSubmit = async values => {
    setSubmiting(true);
    const result = await commandRequest(() =>
      changePassword(values.currentPassword, values.newPassword)
    );
    if (!result.errors) {
      openNotification(
        "success",
        "Operacioni",
        "Fjalëkalimi u ndryshua me sukses!"
      );

      await refreshUserInStore();
      window.location = "/";
    } else {
      setSubmiting(false);
      setErrors(["Fjalëkalimi aktual është i pasaktë!"]);
    }
  };

  return (
    <Skeleton loading={loading} active={true}>
      {item && (
        <div id="edit-profile">
          <Card title="Ndrysho fjalëkalimin">
            <ValidationSummary errors={errors} />
            <Formik
              initialValues={item}
              validationSchema={formValidator}
              onSubmit={onSubmit}
            >
              {formik => (
                <form>
                  <Row>
                    <PasswordInput
                      label="Fjalëkalimi aktual"
                      name="currentPassword"
                      type="password"
                      placeholder="Ju lutemi vendosni fjalëkalimin aktual."
                    />
                  </Row>
                  <Row>
                    <PasswordInput
                      label="Fjalëkalimi i ri"
                      name="newPassword"
                      type="password"
                      placeholder="Ju lutemi vendosni fjalëkalimin e ri."
                    />
                  </Row>

                  <Row>
                    <PasswordInput
                      label="Përsërit Fjalëkalimin e ri"
                      name="passwordConfirmation"
                      type="password"
                      placeholder="Ju lutemi përsërisni fjalëkalimin e ri."
                    />
                  </Row>
                  <Row>
                    <Button
                      type="primary"
                      loading={submiting}
                      disabled={!formValidator.isValidSync(formik.values)}
                      onClick={() => onSubmit(formik.values)}
                    >
                      Ruaj
                    </Button>
                  </Row>
                </form>
              )}
            </Formik>
          </Card>
        </div>
      )}
    </Skeleton>
  );
};

const formValidator = Yup.object({
  currentPassword: Yup.string().required("I detyrueshëm"),
  newPassword: Yup.string().required("I detyrueshëm"),
  passwordConfirmation: Yup.string().when(
    "newPassword",
    (newPassword, schema) => {
      return schema.test({
        test: passwordConfirmation =>
          !!newPassword && passwordConfirmation === newPassword,
        message: "Fjalëkalimi nuk përputhet"
      });
    }
  )
});

export default ChangePassword;
