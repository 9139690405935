import React, {useContext, useEffect, useState} from "react";
import {Col, Row, Select, Spin, Table} from "antd";

import {queryRequest} from "../common/utilities";
import EditDeleteButtons from "../common/components/tables/EditDeleteButtons";
import AddButton from "../common/components/tables/AddButton";
import getSiteUrl from "../siteUrlCreator";
import bannersApi from "core/application/banners/bannersApi";
import store from "core/application/commons/store";
import {AppContext} from "../AppContext";

const Banners = () => {
    const {user, site} = useContext(AppContext)
    const LastBannerSelectedStoreKey = `LastBannerSelected_${user.currentSiteId}`;

    const [items, setItems] = useState([]);
    const [banners, setBanners] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [bannersLoading, setBannersLoading] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null);

    const lastBannerSelected = store.getObject(LastBannerSelectedStoreKey);

    const updateTable = async () => {
        if (!selectedBanner) return;

        setTableLoading(true);

        const banner = await queryRequest(() => bannersApi.getBannerWithItems(selectedBanner.id));
        if (banner) {
            setItems(banner.bannerItems);
        }

        setTableLoading(false);
    };

    const columns = [
        {
            title: "Foto",
            dataIndex: "photoUrl",
            width: "40%",
            render: value => <img src={value} alt=""/>
        },
        {
            title: "Titulli",
            dataIndex: "name",
            sorter: (a, b) => a?.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Prioriteti",
            dataIndex: "priority",
            sorter: (a, b) => a.priority - b.priority,
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Veprim",
            key: "action",
            render: (text, record) => (
                <EditDeleteButtons
                    editUrl={getSiteUrl(`/banners/${selectedBanner.id}/manage-banner-item/${record.id}`)}
                    onOkDelete={() => bannersApi.delete(record.id)}
                    onDeleted={() => updateTable()}
                />
            )
        }
    ];

    useEffect(() => {
        (async () => {
            await updateTable();
        })();
    }, [selectedBanner])

    useEffect(() => {
        (async () => {
            setBannersLoading(true);

            const result = await bannersApi.getAllBanners();
            setBanners(result);

            if (result.length > 0) {
                setSelectedBanner(lastBannerSelected ? lastBannerSelected : result[0])
            }

            setBannersLoading(false);
        })();
    }, []);

    const onBannerChanged = (bannerId) => {
        const found = banners.find(c => c.id === bannerId);
        store.saveObject(LastBannerSelectedStoreKey, found);

        setSelectedBanner({
            ...found
        });
    }

    return (
        <Spin spinning={bannersLoading}>
            {selectedBanner && (
                <>
                    <Row>
                        <Col span={16}>
                            <h4>{selectedBanner.name}</h4>
                        </Col>
                        <Col span={6}>
                            {banners.length > 0 && (
                                <Select defaultValue={selectedBanner.id} onChange={onBannerChanged}>
                                    {banners.map(item => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </Col>
                        <Col span={2}>
                            <div className={"text-right"}>
                                <AddButton addUrl={getSiteUrl(`/banners/${selectedBanner.id}/manage-banner-item`)}/>
                            </div>
                        </Col>
                    </Row>

                    <Table
                        rowKey={record => `${record.id}`}
                        columns={columns}
                        loading={tableLoading}
                        dataSource={items}/>
                </>
            )}
        </Spin>
    );
};

export default Banners;
