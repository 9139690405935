import {adminHttpClient} from "../commons/adminHttpClient";

export default {
  getAll: async () => {
    return adminHttpClient.get(`/blogCategories`);
  },
  delete: async id => adminHttpClient.delete(`/blogCategories/${id}`),
  getForSave: async id => {
    if (id) {
      return adminHttpClient.get(`/blogCategories/forSave/${id}`);
    }
    return adminHttpClient.get(`/blogCategories/forSave`);
  },
  save: async category => adminHttpClient.post(`/blogCategories`, category),
  getByLanguage: async id => adminHttpClient.get(`/blogCategories/byLanguage/${id}`)
};
