import React, {useEffect, useState} from "react";
import {Button, Col, Icon, Input, message, Modal, Row, Upload} from "antd";
import {httpClient} from "core/application/commons/httpClient";
import files from "core/application/files/files";
import Error from "./FormItemError";
import {useField, useFormikContext} from "formik";
import resizeImage from "core/application/commons/imageResizer";
import sitesApi from "core/application/sites/sites";
import notifications from "../notifications/notifications";
import fileUtils from "core/application/commons/fileUtils";

const Uploader = ({
                      fieldName,
                      label,
                      initialValue,
                      className,
                      resizeToWidth = 1080,
                      resizeToHeight = 720,
                      optimizedResize = false,
                      viewImageLibrary = false,
                      viewYoutubeChooser = false,
                      ...props
                  }) => {
    const [loading, setLoading] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [showModalLibrary, setShowModalLibrary] = useState(false);
    const [imagesFromLibrary, setImagesFromLibrary] = useState({items: [], pageCount: 0});
    const [currentPage, setCurrentPage] = useState(1);
    const [librarySearch, setLibrarySearch] = useState("");
    const [field, meta] = useField(props);
    const context = useFormikContext();

    const updateFileUrl = async () => {
        if (!initialValue) {
            return;
        }

        if (fileUtils.isYoutubeLink(initialValue)) {
            setFileUrl(initialValue);
        } else {
            const result = await files.getFileUrl(initialValue);
            setFileUrl(result);
        }
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            await updateFileUrl();
            setLoading(false);
        })();
    }, [initialValue]);

    const handleCustomRequest = async ({file}) => {
        setLoading(true);
        try {
            let result = isImage(file.name)
                ? await uploadImage({
                    file,
                    optimizedResize,
                    resizeToHeight,
                    resizeToWidth
                })
                : await uploadFile({
                    filename: file.name,
                    fileType: file.type,
                    blob: file
                });

            setFileUrl(result.fileUrl);
            context.setFieldValue(fieldName, result.fileId);
            setLoading(false);
        } catch (errMessage) {
            message.error(errMessage);
            setLoading(false);
        }
    };

    return (
        <>
            <label>{label}</label>

            {fileUrl && fileUtils.isYoutubeLink(fileUrl) && (
                <iframe width="560" height="315" src={fileUtils.generateYoutubeEmbedLink(fileUrl)} frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            )}

            {fileUrl && isPdf(fileUrl) && <DisplayPdf fileUrl={fileUrl}/>}
            {fileUrl && isImage(fileUrl) && (
                <DisplayImage fileUrl={fileUrl} resizeToHeight={resizeToHeight}/>
            )}
            {fileUrl && isAudio(fileUrl) && <DisplayAudio fileUrl={fileUrl}/>}
            {fileUrl && isVideo(fileUrl) && <DisplayVideo fileUrl={fileUrl}/>}
            {fileUrl && isWord(fileUrl) && <DisplayWord fileUrl={fileUrl}/>}

            <Row gutter={16}>
                <Col span={3}>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className={className}
                        showUploadList={false}
                        beforeUpload={handleBeforeUpload}
                        customRequest={handleCustomRequest}>
                        <div>
                            <Icon type={loading ? "loading" : "plus"}/>
                            <div className="ant-upload-text">Ngarko</div>
                        </div>
                    </Upload>
                </Col>

                {viewImageLibrary && (
                    <Col span={8}>
                        <div style={{marginTop: "40px"}}>
                            <span className={"mr-16"}>Ose</span>
                            <Button onClick={async () => {
                                const images = await sitesApi.getImageLibrary({pageNumber: currentPage});
                                setShowModalLibrary(true);
                                setImagesFromLibrary(images);
                            }
                            }>Zgjidh nga libraria
                            </Button>
                        </div>
                    </Col>
                )}

                {viewYoutubeChooser && (
                    <Col span={21}>
                        <div style={{marginTop: "40px"}}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <div className={"text-right"}>Ose zgjidh video nga Youtube:</div>
                                </Col>
                                <Col span={18}>
                                    <Input defaultValue={fileUtils.isYoutubeLink(initialValue) ? initialValue : ""}
                                           onBlur={(e) => {
                                               const newFileUrl = e.currentTarget.value;
                                               const embedLink = fileUtils.generateYoutubeEmbedLink(newFileUrl);
                                               if (embedLink) {
                                                   setFileUrl(newFileUrl);
                                                   context.setFieldValue(fieldName, newFileUrl);
                                               } else {
                                                   notifications.showError("Linku i youtube nuk është i saktë");
                                               }
                                           }} placeholder={"Addresa e Youtube"}></Input>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                )}
            </Row>

            <Modal
                title="Libraria"
                visible={showModalLibrary}
                width={"680px"}
                onCancel={() => {
                    setShowModalLibrary(false);
                    setCurrentPage(1);
                }}
                footer={(
                    <Button key="back" onClick={() => {
                        setShowModalLibrary(false);
                        setCurrentPage(1)
                    }}>
                        Mbylle
                    </Button>
                )}>
                <div className={"image-library-wrapper"}>
                    <Input placeholder={"Kerko..."} className={"mb-16"} defaultValue={""} onKeyUp={async (e) => {
                        const ENTER_KEY_CODE = 13;
                        const value = e.target.value;
                        if (e.keyCode === ENTER_KEY_CODE) {
                            const images = await sitesApi.getImageLibrary({pageNumber: 1, q: value});
                            setImagesFromLibrary(images);
                            setCurrentPage(1);
                            setLibrarySearch(value);
                        }
                    }}/>
                    <Row gutter={16}>
                        {imagesFromLibrary.items.map(image => (
                            <Col span={6}>
                                <div className={"image-wrapper"}>
                                    <img className={"mb-16"}
                                         src={image.url}
                                         onClick={() => {
                                             context.setFieldValue(fieldName, image.id);
                                             setFileUrl(image.url)
                                             setShowModalLibrary(false)
                                         }
                                         }/>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <div className={"text-center"}>
                        {currentPage < imagesFromLibrary.pageCount && (
                            <Button onClick={async () => {
                                const nextPage = currentPage + 1;
                                const images = await sitesApi.getImageLibrary({pageNumber: nextPage, q: librarySearch});
                                const result = {
                                    ...imagesFromLibrary
                                };
                                images.items.forEach((item) => {
                                    result.items.push(item);
                                })
                                setImagesFromLibrary(result);
                                setCurrentPage(nextPage);
                            }}>Ngarko më shumë</Button>
                        )}
                    </div>
                </div>
            </Modal>

            {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
        </>
    );
};

const handleBeforeUpload = file => {
    const validFiles = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "audio/mpeg",
        "audio/mp3",
        "video/mp4",
        "image/svg+xml",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];
    const isValidFile = validFiles.includes(file.type);
    if (!isValidFile) {
        message.error(
            "Skedar i pavlefshëm. Ju mund të ngarkoni skedarë të tipeve .jpg, .png, .pdf, .mp3, .mp4, .svg, .docx"
        );
    }
    const expectedSizeInMegaBytes = 200;
    const actualSizeInMegaBytes = file.size / 1024 / 1024;
    const isSizeValid = actualSizeInMegaBytes < expectedSizeInMegaBytes;
    if (!isSizeValid) {
        message.error(
            `Skedari duhet të jetë më i vogël se ${expectedSizeInMegaBytes}MB!`
        );
    }
    return isValidFile && isSizeValid;
};

const uploadFile = async ({filename, fileType, blob}) => {
    try {
        if (fileType === "audio/mpeg") {
            fileType = "audio/mp3";
        }
        const preSignedUrl = await files.getUploadSignedUrl(filename);
        await httpClient.put(preSignedUrl.signedUrl, blob, {
            headers: {
                "Content-Type": fileType,
                "x-amz-acl": "public-read"
            }
        });
        const fileUrl = await files.getFileUrl(preSignedUrl.objectKey);

        return Promise.resolve({
            fileUrl,
            fileId: preSignedUrl.objectKey
        });
    } catch (e) {
        return Promise.reject("Ka ndodhur një gabim gjatë ngarkimit të skedarit.");
    }
};

const uploadImage = async ({
                               file,
                               resizeToWidth = 1080,
                               resizeToHeight = 720,
                               optimizedResize = false
                           }) => {
    try {
        const requiresResize = !isSvg(file.name);
        let blob = file;
        if (requiresResize) {
            blob = await resizeImage(file, {
                maxWidth: resizeToWidth,
                maxHeight: resizeToHeight,
                optimizedResize
            });
        }
        return uploadFile({
            blob,
            filename: file.name,
            fileType: file.type
        });
    } catch (e) {
        return Promise.reject("Ka ndodhur një gabim gjatë ngarkimit të skedarit.");
    }
};

const isPdf = fileUrl => {
    const extension = fileUrl && fileUrl.split(".").pop();
    return extension === "pdf";
};

const isWord = fileUrl => {
    const extension = fileUrl && fileUrl.split(".").pop();
    return extension === "docx";
};

const isAudio = fileUrl => {
    const extension = fileUrl && fileUrl.split(".").pop();
    return extension === "mp3";
};

const isVideo = fileUrl => {
    const extension = fileUrl && fileUrl.split(".").pop();
    return extension === "mp4";
};

const isImage = fileUrl => {
    const imageExtensions = ["jpg", "png", "jpeg", "svg"];
    const extension = fileUrl && fileUrl.split(".").pop();
    return imageExtensions.indexOf(extension) !== -1;
};

const isSvg = fileUrl => {
    const extension = fileUrl && fileUrl.split(".").pop();
    return extension === "svg";
};

const DisplayPdf = ({fileUrl}) => (
    <div className={"mb-16"}>
        <a href={fileUrl} target="_blank">
            <Icon style={{fontSize: "64px"}} type="file-pdf"/>
        </a>
    </div>
);

const DisplayWord = ({fileUrl}) => (
    <div className={"mb-16"}>
        <a href={fileUrl} target="_blank">
            <Icon style={{fontSize: "64px"}} type="file-word"/>
        </a>
    </div>
);

const DisplayImage = ({fileUrl, resizeToHeight}) => {
    const divStyle = {
        border: "1px solid #d9d9d9",
        padding: "0.5rem",
        display: "inline-block"
    };

    const imageStyle = {
        maxWidth: "100%",
        height: `${resizeToHeight}px`
    };

    return (
        <div style={divStyle} className={"mb-16"}>
            <img style={imageStyle} src={fileUrl}/>
        </div>
    );
};

const DisplayAudio = ({fileUrl}) => (
    <div className={"mb-16"}>
        <audio controls src={fileUrl}>
            Your browser does not support the
            <code>audio</code> element.
        </audio>
    </div>
);

const DisplayVideo = ({fileUrl}) => (
    <div className={"mb-16"}>
        <video controls width="320" height="240" src={fileUrl}>
            Your browser does not support the
            <code>video</code> element.
        </video>
    </div>
);

export default Uploader;
