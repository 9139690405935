export default class AudioFilter {
    constructor({
                    pageNumber = 1,
                    orderColumn = "created",
                    orderDescending = true,
                    categoryId = ""
                } = {}) {
        this.pageNumber = pageNumber;
        this.orderColumn = orderColumn;
        this.orderDescending = orderDescending;
        this.categoryId = categoryId
    }
}
