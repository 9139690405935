import React, {useEffect, useState} from "react";
import {Col, Row, Table} from "antd";

import channels from "core/application/channels/channels";
import {queryRequest} from "../common/utilities";
import EditDeleteButtons from "../common/components/tables/EditDeleteButtons";
import AddButton from "../common/components/tables/AddButton";
import getSiteUrl from "../siteUrlCreator";
import {displayDateAndHour} from "../common/displayUtilities";
import PagedList from "../common/models/pagedList";
import OrderedPaginationFilter from "core/application/commons/orderedPaginationFilter";

const Channels = () => {
    const [pagedList, setPagedList] = useState(new PagedList());
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(
        new OrderedPaginationFilter({
            orderColumn: "created"
        })
    );

    const updatePagedChannels = async () => {
        setLoading(true);
        const result = await queryRequest(() => channels.getFiltered(filter));
        setPagedList(result);
        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            await updatePagedChannels();
        })();
    }, [filter]);

    const onChange = (pagination, filters, sorter) => {
        const channelFilter = new OrderedPaginationFilter({
            orderColumn: sorter.field,
            orderDescending: sorter.order !== "ascend",
            pageNumber: pagination.current
        });
        setFilter(channelFilter);
    };

    const columns = [
        {
            title: "Emri",
            dataIndex: "name",
            sorter: true,
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Krijuar në",
            dataIndex: "created",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            render: value => displayDateAndHour(value)
        },
        {
            title: "Veprim",
            key: "action",
            render: (text, record) => (
                <EditDeleteButtons
                    editUrl={getSiteUrl(`/channels/manage-channel/${record.id}`)}
                    onOkDelete={() => channels.delete(record.id)}
                    onDeleted={() => updatePagedChannels()}
                />
            )
        }
    ];

    columns.find(
        c => c.dataIndex === filter.orderColumn
    ).defaultSortOrder = filter.orderDescending ? "descend" : "ascend";

    return (
        <>
            <Row>
                <Col span={16}>
                    <h4>Kanale</h4>
                </Col>
                <Col span={8}>
                    <div className={"text-right"}>
                        <AddButton addUrl={getSiteUrl(`/channels/manage-channel`)}/>
                    </div>
                </Col>
            </Row>
            <Table
                rowKey={"id"}
                columns={columns}
                dataSource={pagedList.items}
                onChange={onChange}
                loading={loading}
                pagination={{
                    total: pagedList.totalItemCount,
                    pageSize: pagedList.pageSize,
                    current: pagedList.pageNumber
                }}
            />
        </>
    );
};

export default Channels;
