import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import Card from "antd/es/card";
import { Form as FormikForm, Formik } from "formik";
import moment from "moment";
import { Spin } from "antd";
import Row from "antd/es/grid/row";

import generateFriendlyUrl from "../common/generateFriendlyUrl";
import openNotification from "../common/components/Notification";
import Input from "../common/components/forms/TextInput";
import Uploader from "../common/components/forms/Uploader";
import Editor from "../common/components/forms/Editor";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import events from "core/application/events/events";
import { commandRequest, queryRequest } from "../common/utilities";
import SaveCancelButtons from "../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../siteUrlCreator";
import Select from "../common/components/forms/Select";
import DatePicker from "../common/components/forms/DatePicker";

const contactFormValidator = Yup.object({
  title: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  friendlyUrl: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  content: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  location: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  time: Yup.date()
    .required("Fushë e detyrueshme")
    .typeError("Fushë e detyrueshme"),
  photoId: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  thumbnailPhotoId: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme")
});

const CategoryToOptionMapper = site => (
  <option key={site.id} value={site.id}>
    {site.name}
  </option>
);

const ManageEvent = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { id } = useParams();
  const pageTitle = id ? "Modifiko eventin" : "Krijo event";
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const updateForm = async () => {
    setLoading(true);
    const item = await queryRequest(() => events.getForSave(id));
    setCategoriesOptions(item.availableCategories.map(CategoryToOptionMapper));
    item.categoryId = item.categoryIds.length > 0 ? item.categoryIds[0] : null;
    if (!id) {
      setItem({
        title: "",
        photoId: "",
        location: "",
        thumbnailPhotoId: "",
        content: "",
        time: moment()
      });
    } else {
      setItem({
        ...item,
        time: moment(item.time, "YYYY-MM-DD HH:mm")
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    updateForm();
  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);

    const result = await commandRequest(() => events.save(values));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification(
        "success",
        "Operacioni",
        "Ruajtja e eventit u krye me sukses."
      );
      history.push(getSiteUrl("/events"));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit}
          >
            {props => (
              <FormikForm>
                <Row>
                  <Input
                    label="Titulli"
                    name="title"
                    type="text"
                    placeholder="Titulli i eventit"
                    onBlur={e => {
                      if (!item.friendlyUrl) {
                        props.setFieldValue(
                          "friendlyUrl",
                          generateFriendlyUrl(e.currentTarget.value)
                        );
                      }
                    }}
                  />
                </Row>

                <Row>
                  <Input
                    label="Url miqësore"
                    name="friendlyUrl"
                    type="text"
                    placeholder="Url miqësore"
                  />
                </Row>

                <Row>
                  <Input
                    label="Vendi"
                    name="location"
                    type="text"
                    placeholder="Vendi i eventit"
                  />
                </Row>

                <Row>
                  <DatePicker
                    label="Zgjidh datën dhe orën"
                    initialValue={item.time}
                    showTime={{ format: "HH:mm" }}
                    name="time"
                    fieldName="time"
                  />
                </Row>

                <Row>
                  <Uploader
                    label="Foto (640 x 426)"
                    initialValue={item.photoId}
                    name="photoId"
                    fieldName="photoId"
                  />
                </Row>

                <Row>
                  <Uploader
                    label="Foto e vogël (640 x 426)"
                    initialValue={item.thumbnailPhotoId}
                    name="thumbnailPhotoId"
                    fieldName="thumbnailPhotoId"
                  />
                </Row>

                <Row>
                  <Editor
                    label="Përmbajtja"
                    initialValue={item.content}
                    name="content"
                    fieldName="content"
                  />
                </Row>

                <Row>
                  {categoriesOptions.length > 0 && (
                    <Select
                      defaultValue={item.categoryId}
                      label="Kategoria"
                      placeholder="Zgjidh kategorinë"
                      name="categoryId"
                      itemName="categoryId"
                    >
                      <option value={null}>[Zgjidh kategori]</option>
                      {categoriesOptions}
                    </Select>
                  )}
                </Row>

                <Row>
                  <SaveCancelButtons loading={saveLoading} />
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManageEvent;
