import {adminHttpClient} from "../commons/adminHttpClient";
import OrderedPaginationFilter from "../commons/orderedPaginationFilter";

export default {
  getFiltered: async (filter = new OrderedPaginationFilter()) => {
    if (!filter) {
      throw new Error("Filtri nuk duhet të jetë bosh.");
    }

    return adminHttpClient.get(`/channels`, filter);
  },
  getAll: async () => {
    return adminHttpClient.get(`/channels/all`);
  },
  delete: async id => adminHttpClient.delete(`/channels/${id}`),
  getForSave: async id => {
    if (id) {
      return adminHttpClient.get(`/channels/forSave/${id}`);
    }
    return adminHttpClient.get(`/channels/forSave`);
  },
  save: async channel => adminHttpClient.post(`/channels`, channel)
};
