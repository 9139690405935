import React from "react";
import styled from "styled-components";
import { useField } from "formik";
import { Input as AntInput } from "antd";
import Error from "./FormItemError";
import StyledLabel from "./StyledLabel";

const TextInput = styled(AntInput)`
  width: 40%;
`;

const Input = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <TextInput.Password {...field} {...props} />
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </>
  );
};

export default Input;
