import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Row, Table} from "antd";

import manageUsers from "core/application/manageUsers/manageUsers";
import {queryRequest} from "../common/utilities";
import AddButton from "../common/components/tables/AddButton";
import {displayDateAndHour} from "../common/displayUtilities";
import OrderedPaginationFilter from "core/application/commons/orderedPaginationFilter";
import PagedList from "../common/models/pagedList";
import TableDeleteButton from "../common/components/tables/TableDeleteButton";
import {ADD_USER_PATH, USERS_ROOT_PATH} from ".";
import {AppContext} from "../AppContext";
import TableEditButton from "../common/components/tables/TableEditButton";
import {useHistory} from "react-router-dom";

const ViewUsers = () => {
  let history = useHistory();
  const {user} = useContext(AppContext);
  const [pagedList, setPagedList] = useState(new PagedList());
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(
      new OrderedPaginationFilter({
        orderColumn: "lastLogin"
      })
  );

  const updatePagedUsers = async () => {
    setLoading(true);
    const result = await queryRequest(() => manageUsers.getAll(filter));
    setPagedList(result);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updatePagedUsers();
    })();
  }, [filter]);

  const onChange = (pagination, filters, sorter) => {
    const userFilter = new OrderedPaginationFilter({
      orderColumn: sorter.field,
      orderDescending: sorter.order !== "ascend",
      pageNumber: pagination.current
    });
    setFilter(userFilter);
  };

  const userIsNotSelf = id => {
    return user.userId !== id;
  };

  const columns = [
    {
      title: "Emri",
      dataIndex: "name",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: (text, record) => <a className={"dark"}>{text}</a>
    },
    {
      title: "Emri i përdoruesit",
      dataIndex: "username",
      sorter: true,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Hyrja e fundit",
      dataIndex: "lastLogin",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: value => displayDateAndHour(value)
    },
    {
      title: "Veprim",
      key: "action",
      render: (text, record) =>
        userIsNotSelf(record.id) && (
              <>
                {user.canDoAnything && (
                    <TableEditButton
                        className="mr-16"
                        editUrl={`/users/manage-user/${record.id}`}
                        appendEditUrlAfterSiteUrl={false}
                    />
                )}
                {user.canDoAnything && (
                    <Button
                        className={"mr-16"}
                        icon="lock"
                        title={"Ndrysho fjalëkalimin"}
                        onClick={() => {
                          history.push(`/users/change-password-for-user/${record.id}`);
                        }}>
                    </Button>
                )}
                {user.canDoAnything && (
                    <TableDeleteButton
                        onDelete={() => manageUsers.delete(record.id)}
                        onDeleted={() => updatePagedUsers()}
                    />
                )}
              </>
          )
    }
  ];

  columns.find(
    c => c.dataIndex === filter.orderColumn
  ).defaultSortOrder = filter.orderDescending ? "descend" : "ascend";

  return (
    <>
      <Row>
        <Col span={16}>
          <h4>Përdoruesit</h4>
        </Col>
        <Col span={8}>
          <div className={"text-right"}>
            <AddButton addUrl={`${USERS_ROOT_PATH}/${ADD_USER_PATH}`} />
          </div>
        </Col>
      </Row>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={pagedList.items}
        onChange={onChange}
        loading={loading}
        pagination={{
          total: pagedList.totalItemCount,
          pageSize: pagedList.pageSize,
          current: pagedList.pageNumber
        }}
      />
    </>
  );
};

export default ViewUsers;
