const isYoutubeLink = fileUrl => fileUrl && fileUrl.includes("https://www.youtube.com");

const generateYoutubeEmbedLink = (url) => {
    if (!url || !isYoutubeLink(url))
        return null;

    const pieces = url.split('v=');
    if (pieces.length < 0)
        return null;

    return `https://www.youtube.com/embed/${pieces[1]}`;
};

export default {
    isYoutubeLink,
    generateYoutubeEmbedLink,
}