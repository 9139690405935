import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useHistory} from "react-router-dom";
import Card from "antd/es/card";
import {Skeleton} from "antd";
import Row from "antd/es/grid/row";
import {Form as FormikForm, Formik} from "formik";

import Input from "../common/components/forms/TextInput";
import Uploader from "../common/components/forms/Uploader";
import manageSites from "core/application/sites/sites";
import {commandRequest, queryRequest} from "../common/utilities";
import SaveButton from "../common/components/forms/SaveButton";
import CancelButton from "../common/components/forms/CancelButton";
import Area from "../common/components/forms/TextArea";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import notifications from "../common/components/notifications/notifications";
import festivalApi from "core/application/festivals/festivals";
import Select from "../common/components/forms/Select";
import getCurrentSite from "../currentSite";

const formValidator = Yup.object({
    logo: Yup.string()
        .nullable()
        .trim()
        .required("Fushë e detyrueshme")
});

const SiteSettings = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [item, setItems] = useState();
    const [saveLoading, setSaveLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [festivals, setFestivals] = useState([]);

    const updateSettings = async () => {
        setLoading(true);

        const result = await queryRequest(() => manageSites.getForSave());
        setItems(result ? result : null);

        setFestivals(await festivalApi.getAll())

        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            await updateSettings();
        })();
    }, []);

    const onSubmit = async values => {
        setSaveLoading(true);

        const result = await commandRequest(() => manageSites.save(values));

        setSaveLoading(false);

        if (!result.errors) {
            notifications.showSuccess();
            window.location.reload();
        } else {
            setErrors(result.errors);
            window.scrollTo(0, 0);
        }
    };

    return (
        <Skeleton loading={loading}>
            {item && (
                <Formik enableReinitialize={true}
                        initialValues={item}
                        validationSchema={formValidator}
                        onSubmit={onSubmit}>
                    {props => (
                        <FormikForm>
                            <ValidationSummary errors={errors}/>
                            <Card title={"Logo (100 x 30)"} className={"mb-16"}>
                                <Row>
                                    <Uploader
                                        label=""
                                        initialValue={item.logo}
                                        name="logo"
                                        resizeToWidth={100}
                                        resizeToHeight={30}
                                        fieldName="logo"
                                    />
                                </Row>
                            </Card>

                            <Card title={"Favicon (32 x 32)"} className={"mb-16"}>
                                <Row>
                                    <Uploader
                                        label=""
                                        initialValue={item.favicon}
                                        name="favicon"
                                        resizeToWidth={32}
                                        resizeToHeight={32}
                                        fieldName="favicon"
                                    />
                                </Row>
                            </Card>

                            <Card title={"Seo"} className={"mb-16"}>
                                <Row>
                                    <Input label="Titulli" name="seoTitle" type="text" placeholder="Titulli"/>
                                </Row>

                                <Row>
                                    <Area label="Përshkrimi(160 Karaktere)" name="seoDescription"
                                          placeholder="Përshkrimi"/>
                                </Row>

                                <Row>
                                    <Input label="Fjalë Kyçe" name="seoKeywords" type="text" placeholder="Fjalë Kyçe"/>
                                </Row>
                            </Card>

                            <Card title={"Google Analytics"} className={"mb-16"}>
                                <Row>
                                    <Input label="" name="googleAnalyticsId" type="text"
                                           placeholder="Google Analytics Code"/>
                                </Row>
                            </Card>

                            {getCurrentSite().showChangeCurrentFestival && festivals.length && (
                                <Card title={"Zgjidhni festivalin aktual"} className={"mb-16"}>
                                    <Row>
                                        {festivals.length > 0 && (
                                            <Select
                                                defaultValue={item.currentFestivalId}
                                                label=""
                                                placeholder="Zgjidhni festivalin aktual"
                                                name="currentFestivalId"
                                                itemName="currentFestivalId">
                                                <option value={null}>[Zgjidhni festivalin aktual]</option>
                                                {festivals.map(festival => (
                                                    <option key={festival.id} value={festival.id}>
                                                        {`Fest ${festival.edition}`}
                                                    </option>
                                                ))}
                                            </Select>
                                        )}
                                    </Row>
                                </Card>
                            )}

                            <div className={"text-center mb-16"}>
                                <SaveButton loading={saveLoading} className="mr-16"/>
                                <CancelButton/>
                            </div>

                        </FormikForm>
                    )}

                </Formik>
            )}
        </Skeleton>
    );
};

export default SiteSettings;
