import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import Card from "antd/es/card";
import { Form as FormikForm, Formik } from "formik";
import { Spin } from "antd";
import Row from "antd/es/grid/row";

import Input from "../common/components/forms/TextInput";
import Uploader from "../common/components/forms/Uploader";
import ValidationSummary from "../common/components/forms/ValidationSummary";

import { commandRequest, queryRequest } from "../common/utilities";
import SaveCancelButtons from "../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../siteUrlCreator";
import bannersApi from "core/application/banners/bannersApi";
import notifications from "../common/components/notifications/notifications";
import Editor from "../common/components/forms/Editor";

const contactFormValidator = Yup.object({
  photoId: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme")
});

const ManageBanner = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { id, bannerId } = useParams();

  const pageTitle = id ? "Modifiko banerin" : "Krijo baner";

  const updateForm = async () => {
    setLoading(true);
    const result = await queryRequest(() => bannersApi.getForSave(bannerId, id));
    setItem(result);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await updateForm();
    })();

  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);

    const result = await commandRequest(() => bannersApi.save({
      ...values,
      id,
      bannerId
    }));

    setSaveLoading(false);

    if (!result.errors) {
      notifications.showSuccess();
      history.push(getSiteUrl(`/banners`));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit} >
            <FormikForm>
              <Row>
                <Uploader
                  label={`Foto (${item.settings.imageWidth} x ${item.settings.imageHeight})`}
                  initialValue={item.photoId}
                  resizeToWidth={item.settings.imageWidth}
                  resizeToHeight={item.settings.imageHeight}
                  name="photoId"
                  fieldName="photoId"
                />
              </Row>

              <Row>
                <Input label="Titulli" name="name" type="text" placeholder="Titulli i banerit" />
              </Row>

              <Row>
                <Input label="Url" name="url" type="text" placeholder="Url" />
              </Row>

              {item.settings.showDescription && (
                  <Row>
                    <Editor label="Përmbajtja" initialValue={item.description} name="description" fieldName="description" />
                  </Row>
              )}

              <Row>
                <Input label="Prioriteti" name="priority" type="number" placeholder="Prioriteti" />
              </Row>

              <Row>
                <div className={"text-center"}>
                  <SaveCancelButtons loading={saveLoading} />
                </div>
              </Row>
            </FormikForm>
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManageBanner;
