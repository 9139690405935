import React from "react";
import styled from "styled-components";
import { Input as AntInput } from "antd";

const TextInput = styled(AntInput)`
  width: 40%;
`;

const Input = props => {
  return (
    <>
      <TextInput {...props} className={props.className} />
    </>
  );
};

export default Input;
