const SiteIds = {
    FESTIVAL_ID: '1',
    RTSH_SPORT_ID: '2',
    RTSH_24_ID: '3',
    RTSH_MAIN: '4',
    RTSH_TV: '5',
    RTSH_RTI: '6',
    RTSH_RADIO: '7',
    RTSH_ORCHESTRA: '8',
}

export {SiteIds}