import React, {useState} from "react";
import {DatePicker as AntDatepicker} from "antd";
import Error from "./FormItemError";
import {useField, useFormikContext} from "formik";
import StyledLabel from "./StyledLabel";
import moment from "moment";

const Editor = ({label, initialValue, showTime, fieldName, ...props}) => {
    const [field, meta] = useField(props);
    const context = useFormikContext();
    const [value, setValue] = useState(initialValue);

  const onDateChange = (date, dateString) => {
    context.setFieldValue(
      fieldName,
      moment(dateString).utc("YYYY-MM-DD HH:mm:ss")
    );
    setValue(moment(dateString, "YYYY-MM-DD HH:mm:ss"));
  };

  return (
    <>
        <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
        <AntDatepicker
            defaultValue={initialValue}
            showTime={showTime}
            onChange={onDateChange}
        />
      {meta.touched || meta.error ? <Error>{meta.error}</Error> : null}
    </>
  );
};

export default Editor;
