import React from "react";
import {Editor as TextEditor} from "@tinymce/tinymce-react";
import Error from "./FormItemError";
import {useField, useFormikContext} from "formik";
import StyledLabel from "./StyledLabel";
import files from "core/application/files/files";
import {httpClient} from "core/application/commons/httpClient";
import resizeImage from "core/application/commons/imageResizer";

const Editor = ({ fieldName, label, initialValue, ...props }) => {
  const [field, meta] = useField(props);
  const context = useFormikContext();

  const handleEditorChange = e => {
    context.setFieldValue(fieldName, e.target.getContent());
  };

  const handleEditorBlur = () => {
    context.setFieldTouched(fieldName, true);
  };

  return (
    <>
        <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
        <TextEditor
            apiKey="ksspwqci2t2s16g0enoe1lcybmup5mzbym550putf9lxyh96"
            init={{
                height: 500,
                width: "100%",
                menubar: "file edit view insert format tools table tc help",
                plugins: [
                    'advlist autolink lists link image charmap imagetools print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
                quickbars_selection_toolbar: "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                contextmenu: "paste | link image imagetools | cell row column deletetable",
                images_upload_handler: handleImageUpload
            }}
        initialValue={initialValue}
        onChange={handleEditorChange}
        onBlur={handleEditorBlur}
      />
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </>
  );
};

const handleImageUpload = (blobInfo, success, failure) => {
  let fileId = "";
  const file = blobInfo.blob();
  let resizedBlob;
  resizeImage(file, {maxWidth: 640, maxHeight: 426})
    .then(blob => {
      resizedBlob = blob;
      return files.getUploadSignedUrl(file.name);
    })
    .then(result => {
      const signedUrl = result.signedUrl;
      fileId = result.objectKey;
      const options = {
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read"
        }
      };

      return httpClient.put(signedUrl, resizedBlob, options);
    })
    .then(() => {
      return files.getFileUrl(fileId);
    })
    .then(fileUrl => {
      success(fileUrl);
    })
    .catch(() => {
      failure("An error occured while uploading the file.");
    });
};

export default Editor;
