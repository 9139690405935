import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import Card from "antd/es/card";
import { Form as FormikForm, Formik } from "formik";
import { Spin } from "antd";
import Row from "antd/es/grid/row";

import generateFriendlyUrl from "../../common/generateFriendlyUrl";
import openNotification from "../../common/components/Notification";
import Input from "../../common/components/forms/TextInput";
import Uploader from "../../common/components/forms/Uploader";
import ValidationSummary from "../../common/components/forms/ValidationSummary";
import { commandRequest, queryRequest } from "../../common/utilities";
import SaveCancelButtons from "../../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../../siteUrlCreator";
import publicationCategories from "core/application/publications/publicationCategories";

const contactFormValidator = Yup.object({
  name: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  friendlyUrl: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
    photoId: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme")
});

const ManagePublicationCategory = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { id } = useParams();
  const pageTitle = id ? "Modifiko kategorinë" : "Krijo kategori";
  const [parentCategoriesOptions, setParentCategoriesOptions] = useState([]);

  const updateForm = async () => {
    setLoading(true);
    const item = await queryRequest(() => publicationCategories.getForSave(id));
    setItem(item);
    setLoading(false);
  };

  useEffect(() => {
    updateForm();
  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);
    if (values.id == null) values.id = "";

    const itemToSave = {
      ...values
    };

    const result = await commandRequest(() =>
      publicationCategories.save(itemToSave)
    );
    setSaveLoading(false);

    if (!result.errors) {
      openNotification(
        "success",
        "Operacioni",
        "Ruajtja e kategorisë u krye me sukses."
      );
      history.push(getSiteUrl("/publicationCategories"));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit}
          >
            {props => (
              <FormikForm>
                <Row>
                  <Input
                    label="Emri"
                    name="name"
                    type="text"
                    placeholder="Emri i kategorisë"
                    onBlur={e => {
                      if (!item.friendlyUrl) {
                        props.setFieldValue(
                          "friendlyUrl",
                          generateFriendlyUrl(e.currentTarget.value)
                        );
                      }
                    }}
                  />
                </Row>

                <Row>
                  <Input
                    label="Url miqësore"
                    name="friendlyUrl"
                    type="text"
                    placeholder="Url miqësore"
                  />
                </Row>

                <Row>
                  <Uploader
                    label="Foto (640 x 426)"
                    initialValue={item.photoId}
                    name="photoId"
                    resizeToWidth={640}
                    resizeToHeight={426}
                    fieldName="photoId"
                  />
                </Row>

                <Row>
                  <SaveCancelButtons loading={saveLoading} />
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManagePublicationCategory;
