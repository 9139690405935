import { Switch } from "react-router-dom";
import React from "react";

import ProtectedRoute from "../common/configs/routes/protectedRoute";
import Articles from "../articles/Articles";
import ManageArticle from "../articles/ManageArticle";
import Banners from "../banners/Banners";
import ManageBanner from "../banners/ManageBanner";
import BlogCategories from "../articles/categories/BlogCategories";
import ManageBlogCategory from "../articles/categories/ManageBlogCategory";
import Audio from "../audios/Audio";
import ManageAudio from "../audios/ManageAudio";
import getLoginUser from "core/application/users/getLoginUser";
import Permission from "core/domain/users/Permission";

const RTI_ROOT_PATH = "/rti";

const user = getLoginUser();

const rtiRoutes = [
  {
    icon: "pie-chart",
    key: "menu-2",
    path: `${RTI_ROOT_PATH}/articles`,
    name: "Artikuj"
  }
];

if (user && user.canDoAnything) {
  rtiRoutes.push(
    {
      icon: "pie-chart",
      key: "menu-1",
      path: `${RTI_ROOT_PATH}/banners`,
      name: "Banera"
    },
    {
      icon: "pie-chart",
      key: "menu-3",
      path: `${RTI_ROOT_PATH}/blogCategories`,
      name: "Kategoritë"
    },
    {
      icon: "pie-chart",
      key: "menu-4",
      path: `${RTI_ROOT_PATH}/podcast`,
      name: "Podcast"
    }
  );
}

const RtiRouter = () => (
  <Switch>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTI_ROOT_PATH}/banners`}
    >
      <Banners />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTI_ROOT_PATH}/banners/:bannerId/manage-banner-item/:id?`}
    >
      <ManageBanner />
    </ProtectedRoute>

    <ProtectedRoute exact path={`${RTI_ROOT_PATH}/articles`}>
      <Articles />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[
        Permission.All,
        Permission.SaveArticle,
        Permission.PublishArticle
      ]}
      exact
      path={`${RTI_ROOT_PATH}/articles/manage-article/:id?`}
    >
      <ManageArticle />
    </ProtectedRoute>

    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTI_ROOT_PATH}/blogCategories`}
    >
      <BlogCategories />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTI_ROOT_PATH}/blogCategories/manage-category/:id?`}
    >
      <ManageBlogCategory />
    </ProtectedRoute>

    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTI_ROOT_PATH}/podcast`}
    >
      <Audio />
    </ProtectedRoute>
    <ProtectedRoute
      permissions={[Permission.All]}
      exact
      path={`${RTI_ROOT_PATH}/podcast/manage-podcast/:id?`}
    >
      <ManageAudio />
    </ProtectedRoute>
  </Switch>
);

export { RtiRouter, RTI_ROOT_PATH, rtiRoutes };
