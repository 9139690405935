import { adminHttpClient } from "../commons/adminHttpClient";
import OrderedPaginationFilter from "../commons/orderedPaginationFilter";

export default {
  getAll: async (filter = new OrderedPaginationFilter()) => {
    if (!filter) {
      throw new Error("Filtri nuk duhet të jetë bosh.");
    }

    return adminHttpClient.get(
      `/publication?pageNumber=${filter.pageNumber}&orderColumn=${filter.orderColumn}&orderDescending=${filter.orderDescending}`
    );
  },
  delete: async id => adminHttpClient.delete(`/publication/${id}`),
  getForSave: async id => {
    if (id) {
      return adminHttpClient.get(`/publication/forSave/${id}`);
    }
    return adminHttpClient.get(`/publication/forSave`);
  },
  save: async publication => adminHttpClient.post(`/publication`, publication)
};
