const resizeImage = (
  file,
  { maxWidth = 1080, maxHeight = 720, optimizedResize = true } = {}
) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = readerEvent => {
      const image = new Image();
      image.onload = () => {
        let { resizeWidth, resizeHeight } = calculateWidthAndHeight(
          image.width,
          image.height,
          maxWidth,
          maxHeight,
          optimizedResize
        );

        const canvas = document.createElement("canvas");
        canvas.width = resizeWidth;
        canvas.height = resizeHeight;
        canvas
          .getContext("2d")
          .drawImage(image, 0, 0, resizeWidth, resizeHeight);
        const dataUrl = canvas.toDataURL("image/jpeg");
        resolve(dataURLToBlob(dataUrl));
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

const calculateWidthAndHeight = (
  imageWidth,
  imageHeight,
  desiredWidth,
  desiredHeight,
  optimizedResize
) => {
  if (!optimizedResize) {
    return {
      resizeWidth: desiredWidth,
      resizeHeight: desiredHeight
    };
  }

  if (imageWidth > imageHeight) {
    if (imageWidth > desiredWidth) {
      imageHeight *= desiredWidth / imageWidth;
      imageWidth = desiredWidth;
    }
  } else {
    if (imageHeight > desiredHeight) {
      imageWidth *= desiredHeight / imageHeight;
      imageHeight = desiredHeight;
    }
  }

  return {
    resizeWidth: imageWidth,
    resizeHeight: imageHeight
  };
};

const dataURLToBlob = dataURL => {
  const BASE64_MARKER = ";base64,";
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    const parts = dataURL.split(",");
    const contentType = parts[0].split(":")[1];
    const raw = parts[1];

    return new Blob([raw], { type: contentType });
  }

  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(":")[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};

export default resizeImage;
