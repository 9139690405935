import { adminHttpClient } from "../commons/adminHttpClient";

export default {
  getAll: async () => adminHttpClient.get("/eventsCategories"),
  delete: async id => adminHttpClient.delete(`/eventsCategories/${id}`),
  getForSave: async id => {
    if (id) {
      return adminHttpClient.get(`/eventsCategories/forSave/${id}`);
    }
    return adminHttpClient.get(`/eventsCategories/forSave`);
  },
  save: async category => adminHttpClient.post(`/eventsCategories`, category)
};
