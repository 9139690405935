import ProtectedRoute from "../common/configs/routes/protectedRoute";
import React from "react";
import loadable from "@loadable/component";
import {options} from "../common/configs/routes";
import {Switch} from "react-router-dom";
import getLoginUser from "core/application/users/getLoginUser";
import Permission from "core/domain/users/Permission";
import {ORCHESTRA_ROOT_PATH} from "../orchestra";
import Menu from "../menus/Menu";
import Page from "../pages/Page";
import ManagePage from "../pages/ManagePage";

const Sponsors = loadable(() => import("./sponsors/Sponsors"), options);
const ManageSponsor = loadable(
    () => import("./sponsors/ManageSponsor"),
    options
);
const Participants = loadable(() => import("./participants/Participants"));
const ManageParticipant = loadable(() =>
  import("./participants/ManageParticipant")
);
const Songs = loadable(() => import("./songs/Songs"), options);
const ManageSong = loadable(() => import("./songs/ManageSong"), options);
const Juries = loadable(() => import("./juries/Juries"), options);
const ManageJury = loadable(() => import("./juries/ManageJury"), options);
const Artists = loadable(() => import("./artists/Artists"), options);
const ManageArtist = loadable(() => import("./artists/ManageArtist"), options);
const Festivals = loadable(() => import("./Festivals"), options);
const ManageFestival = loadable(() => import("./ManageFestival"), options);
const Nights = loadable(() => import("./nights/Nights"), options);
const ManageNight = loadable(() => import("./nights/ManageNight"), options);
const Articles = loadable(() => import("../articles/Articles"), options);
const ManageArticle = loadable(
  () => import("../articles/ManageArticle"),
  options
);
const Banners = loadable(() => import("../banners/Banners"), options);
const ManageBanner = loadable(() => import("../banners/ManageBanner"), options);
const Galleries = loadable(() => import("../galleries/Galleries"), options);
const GalleryItems = loadable(
  () => import("../galleries/GalleryItems"),
  options
);
const ManageGallery = loadable(
  () => import("../galleries/ManageGallery"),
  options
);
const ManageGalleryItem = loadable(
  () => import("../galleries/ManageGalleryItem"),
  options
);

const FESTIVAL_ROOT_PATH = "/festival";

const festivalRoutes = [
  {
    icon: "pie-chart",
    key: "menu-2",
    path: `${FESTIVAL_ROOT_PATH}/articles`,
    name: "Artikuj"
  }
];

const user = getLoginUser();

if (user && user.canDoAnything) {
  festivalRoutes.push(
      {
          icon: "pie-chart",
          key: "menu-1",
          path: "/",
          name: "Festivali aktual",
          children: [
              {
                  icon: "pie-chart",
                  key: "menu-1-1",
                  path: `${FESTIVAL_ROOT_PATH}/songs`,
                  name: "Këngët Konkurruese"
              },
              {
                  icon: "pie-chart",
                  key: "menu-1-2",
          path: `${FESTIVAL_ROOT_PATH}/realization-group`,
          name: "Grupi i Realizimit"
        },
        {
          icon: "pie-chart",
          key: "menu-1-3",
          path: `${FESTIVAL_ROOT_PATH}/juries`,
          name: "Juria"
        },
        {
          icon: "pie-chart",
          key: "menu-1-4",
          path: `${FESTIVAL_ROOT_PATH}/nights`,
          name: "Netët e Festivalit"
        },
        {
          icon: "pie-chart",
          key: "menu-1-5",
          path: `${FESTIVAL_ROOT_PATH}/sponsors`,
          name: "Sponsor"
        }
      ]
    },
    {
        icon: "pie-chart",
        key: "menu-3",
        path: `${FESTIVAL_ROOT_PATH}/galleries`,
        name: "Galeria"
    },
      {
          icon: "pie-chart",
          key: "menu-4",
          path: `${FESTIVAL_ROOT_PATH}/banners`,
          name: "Banera"
      },
      {
          icon: "pie-chart",
          key: "menu-14",
          path: `${FESTIVAL_ROOT_PATH}/history`,
          name: "Festivalet"
      },
      {
          icon: "pie-chart",
          key: "menu-15",
          path: `${FESTIVAL_ROOT_PATH}/artists`,
          name: "Artistët"
      },
      {
          icon: "pie-chart",
          key: "menu-26",
          path: `${FESTIVAL_ROOT_PATH}/menu`,
          name: "Menu"
      },
      {
          icon: "pie-chart",
          key: "menu-36",
          path: `${FESTIVAL_ROOT_PATH}/pages`,
          name: "Faqe"
      },
  );
}

const FestivalRouter = () => {
  return (
    <Switch>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/songs`}>
            <Songs/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/songs/manage-song/:id?`}>
            <ManageSong/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/realization-group`}>
            <Participants/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/realization-group/manage-realization-group/:id?`}>
            <ManageParticipant/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/history`}>
            <Festivals/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/history/manage-festival/:id?`}>
            <ManageFestival/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/juries`}>
            <Juries/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/juries/manage-jury/:id?`}>
            <ManageJury/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/nights`}>
            <Nights/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/nights/manage-night/:id?`}>
            <ManageNight/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/sponsors`}>
            <Sponsors/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/sponsors/manage-sponsor/:id?`}>
            <ManageSponsor/>
        </ProtectedRoute>

        <ProtectedRoute exact path={`${FESTIVAL_ROOT_PATH}/artists`}>
            <Artists/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/artists/manage-artist/:id?`}>
            <ManageArtist/>
        </ProtectedRoute>

        <ProtectedRoute exact path={`${FESTIVAL_ROOT_PATH}/articles`}>
            <Articles/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[
                Permission.All,
                Permission.SaveArticle,
                Permission.PublishArticle
            ]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/articles/manage-article/:id?`}>
            <ManageArticle/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/banners`}>
            <Banners/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/banners/:bannerId/manage-banner-item/:id?`}>
            <ManageBanner/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/galleries`}>
            <Galleries/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/galleries/manage-gallery/:id?`}>
            <ManageGallery/>
        </ProtectedRoute>

        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/gallery-items/:friendlyUrl`}>
            <GalleryItems/>
        </ProtectedRoute>
        <ProtectedRoute
            permissions={[Permission.All]}
            exact
            path={`${FESTIVAL_ROOT_PATH}/gallery-items/manage-gallery-item/:galleryId/:friendlyUrl/:id?`}>
            <ManageGalleryItem/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${FESTIVAL_ROOT_PATH}/menu`}>
            <Menu/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact path={`${FESTIVAL_ROOT_PATH}/pages`}>
            <Page/>
        </ProtectedRoute>

        <ProtectedRoute permissions={[Permission.All]} exact
                        path={`${FESTIVAL_ROOT_PATH}/pages/manage-page/:id?`}>
            <ManagePage/>
        </ProtectedRoute>

    </Switch>
  );
};

export { FestivalRouter, FESTIVAL_ROOT_PATH, festivalRoutes };
