import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Button, Col, Modal, Row, Select, Table, Tag} from "antd";
import {createDndContext, DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import articles from "core/application/articles/articles";
import {queryRequest} from "../common/utilities";
import AddButton from "../common/components/tables/AddButton";
import getSiteUrl from "../siteUrlCreator";
import {displayDate} from "../common/displayUtilities";
import PagedList from "../common/models/pagedList";
import ArticleStatus from "core/domain/blog/articleStatus";
import TableEditButton from "../common/components/tables/TableEditButton";
import TableDeleteButton from "../common/components/tables/TableDeleteButton";
import {AppContext} from "../AppContext";
import ArticleSearch from "./ArticleSearch";
import CheckCircleFilled from "@ant-design/icons/lib/icons/CheckCircleFilled";
import ArticlesFilter from "core/application/articles/ArticlesFilter";
import languagesApi from "core/application/languages/languagesApi";
import store from "core/application/commons/store";
import ArticleHistoryTable from "./ArticleHistoryTable";

const RNDContext = createDndContext(HTML5Backend);
const type = 'DragableBodyRow';

const DragableBodyRow = ({index, moveRow, className, style, ...restProps}) => {
    const ref = React.useRef();
    const [{isOver, dropClassName}, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const {index: dragIndex} = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: item => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        item: {type, index},
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{cursor: 'move', ...style}}
            {...restProps}
        />
    );
};

const Articles = () => {
    const {user, site} = useContext(AppContext);

    const LastFilterStoreKey = `LastFilter_${user.currentSiteId}`;
    const [pagedList, setPagedList] = useState(new PagedList());
    const [loading, setLoading] = useState(true);
    const storedFilter = store.getObject(LastFilterStoreKey);
    const [showArticleHistoryModal, setShowArticleHistoryModal] = useState(false);
    const [articleHistoryLog, setArticleHistoryLog] = useState([]);
    const [filter, setFilter] = useState(storedFilter
        ? new ArticlesFilter({...storedFilter})
        : new ArticlesFilter({
            orderColumn: "priority",
            orderDescending: true
        })
    );
    const [languages, setLanguages] = useState([]);

    const updatePagedArticles = async () => {
        setLoading(true);

        setPagedList(await queryRequest(() => articles.getAll(filter)));

        setLoading(false);
        store.saveObject(LastFilterStoreKey, filter);
    };

    useEffect(() => {
        (async () => {
            const result = await languagesApi.getAll();
            setLanguages(result);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await updatePagedArticles();
        })();
    }, [filter]);

    const onTableChange = (pagination, filters, sorter) => {
        const articleFilter = new ArticlesFilter({
            ...filter,
            orderColumn: "priority",
            orderDescending: sorter.order !== "ascend",
            pageNumber: pagination.current
        });
        setFilter(articleFilter);
    };

    const onSearch = (searchValue) => {
        setFilter(new ArticlesFilter({
            ...filter,
            searchValue
        }));
    };

    const onLanguageChange = (languageId) => {
        setFilter(new ArticlesFilter({
            ...filter,
            languageId: languageId
        }));
    }

    const components = {
        body: {
            row: DragableBodyRow,
        },
    };

    const moveRow = useCallback(
        async (dragIndex, hoverIndex) => {
            const dragRow = pagedList.items[dragIndex];
            const hoverRow = pagedList.items[hoverIndex];
            setLoading(true);
            await articles.swapOrder(dragRow.id, hoverRow.id);
            await updatePagedArticles();
            setLoading(false);
        },
        [pagedList.items],
    );

    const manager = useRef(RNDContext);

    const columns = [
        {
            title: "Titull",
            dataIndex: "title",
            sorter: false,
            sortDirections: ["descend", "ascend"],
            width: "40%",
            render: (text, record) => (
                <a className={"dark"} href={record.friendlyUrl}>
                    {text}
                </a>
            )
        },
        {
            title: "Kategoria",
            dataIndex: "category",
            sorter: false,
            sortDirections: ["descend", "ascend"],
            render: (text, record) => {
                return record.category ? record.category.name : "";
            }
        },
        {
            title: "Kryesues",
            dataIndex: "leading",
            sorter: false,
            sortDirections: ["descend", "ascend"],
            render: (text, record) => (
                record.leading ? (<CheckCircleFilled/>) : ""
            )
        },
        {
            title: "Statusi",
            dataIndex: "status",
            sorter: false,
            sortDirections: ["descend", "ascend"],
            render: (text, record) => {
                const tagColor =
                    record.status.key === ArticleStatus.Draft ? "orange" : "green";
                return <Tag color={tagColor}>{record.status.value}</Tag>;
            }
        },
        {
            title: "Krijuar në",
            dataIndex: "created",
            sorter: false,
            sortDirections: ["descend", "ascend"],
            render: value => displayDate(value)
        },
        {
            title: "Veprim",
            key: "action",
            render: (text, record) => (
                <>
                    {user.canSaveArticle && (
                        <TableEditButton
                            className="mr-16"
                            editUrl={`/articles/manage-article/${record.id}`}
                        />
                    )}
                    {user.canDeleteArticle && (
                        <TableDeleteButton
                            className="mr-16"
                            onDelete={() => articles.delete(record.id)}
                            onDeleted={() => updatePagedArticles()}
                        />
                    )}

                    {user.canDoAnything && (
                        <Button
                            icon="history"
                            onClick={async () => {
                                const result = await articles.getHistoryLogForArticle(record.id);
                                setArticleHistoryLog(result);
                                setShowArticleHistoryModal(true);
                            }}>
                        </Button>
                    )}
                </>
            )
        }
    ];

    return (
        <>
            <Row gutter={16}>
                <Col span={10}>
                    <h4>Artikuj</h4>
                </Col>
                <Col span={4}>
                    {site.showLanguages &&
                    (
                        <Select defaultValue={filter.languageId} onChange={onLanguageChange}>
                            <option value={""}>Të gjitha gjuhët</option>
                            {languages.map(item => (
                                <option key={item.name} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    )}
                </Col>
                <Col span={8}>
                    <ArticleSearch
                        placeholder={"Kërko artikull me titull ose përmbajtje"}
                        onSearch={onSearch}
                        initialValue={filter.searchValue}
                    />
                </Col>
                <Col span={2}>
                    <div className={"text-right"}>
                        <AddButton addUrl={getSiteUrl(`/articles/manage-article`)} visible={user.canSaveArticle}/>
                    </div>
                </Col>
            </Row>

            <DndProvider manager={manager.current.dragDropManager}>
                <Table
                    rowKey={"id"}
                    columns={columns}
                    dataSource={pagedList.items}
                    onChange={onTableChange}
                    loading={loading}
                    pagination={{
                        total: pagedList.totalItemCount,
                        pageSize: pagedList.pageSize,
                        current: pagedList.pageNumber
                    }}
                    components={components}
                    onRow={(record, index) => ({
                        index,
                        moveRow,
                    })}
                />
            </DndProvider>

            {articleHistoryLog.length > 0 && (
                <Modal
                    title={`${articleHistoryLog[0].article.name}`}
                    visible={showArticleHistoryModal}
                    width={"800px"}
                    onCancel={() => {
                        setShowArticleHistoryModal(false);
                    }}
                    footer={(
                        <Button key="back" onClick={() => {
                            setShowArticleHistoryModal(false);
                        }}> Mbylle</Button>
                    )}>
                    <ArticleHistoryTable articleHistoryLog={articleHistoryLog}/>
                </Modal>
            )}
        </>
    );
};


export default Articles;
