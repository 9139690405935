import React from "react";
import { Button, message, Modal } from "antd";
import { commandRequest } from "../../utilities";
import { useHistory } from "react-router-dom";

const { confirm } = Modal;

const EditDeleteButtons = ({
  editUrl,
  onOkDelete,
  onDeleted,

  disableDeleted = false,
  deleteTitle = "",
  title = "Jeni të sigurt që doni ta fshini?",
  content = "Ky veprim nuk mund të kthehet mbrapsht!"
}) => {
  const history = useHistory();
  return (
    <>
      <Button
        className="mr-8"
        icon="edit"
        onClick={() => {
          history.push(editUrl);
        }}
      ></Button>
      <Button
        title={deleteTitle}
        disabled={disableDeleted}
        icon="delete"
        onClick={() => {
          confirm({
            title,
            content,
            onOk: async () => {
              const result = await commandRequest(() => onOkDelete());
              if (!result.errors) {
                if (onDeleted) {
                  onDeleted();
                }
              } else {
                message.error(result.errors[0]);
              }
            },
            onCancel() {}
          });
        }}
      ></Button>
    </>
  );
};

export default EditDeleteButtons;
