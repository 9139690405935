import React from "react";

import Input from "../common/components/dataEntry/Input";

const SearchInput = ({placeholder = 'Kërko', onSearch, initialValue = null}) => {
  const ENTER_KEY_CODE = 13;
  const search = e => {
    const value = e.target.value;
    if (e.keyCode === ENTER_KEY_CODE) {
      onSearch(value);
    }
  };

  return (
      <div>
        <Input placeholder={placeholder} defaultValue={initialValue} onKeyUp={search}/>
    </div>
  );
};

export default SearchInput;
