import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useHistory, useParams} from "react-router-dom";
import Card from "antd/es/card";
import {Form as FormikForm, Formik} from "formik";
import {Spin} from "antd";
import Row from "antd/es/grid/row";

import generateFriendlyUrl from "../common/generateFriendlyUrl";
import openNotification from "../common/components/Notification";
import Input from "../common/components/forms/TextInput";
import Uploader from "../common/components/forms/Uploader";
import Editor from "../common/components/forms/Editor";
import ValidationSummary from "../common/components/forms/ValidationSummary";
import publications from "core/application/publications/publication";
import {commandRequest, queryRequest} from "../common/utilities";
import SaveCancelButtons from "../common/components/forms/SaveCancelButtons";
import getSiteUrl from "../siteUrlCreator";
import Select from "../common/components/forms/Select";

const contactFormValidator = Yup.object({
  title: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
  friendlyUrl: Yup.string()
    .nullable()
    .trim()
    .required("Fushë e detyrueshme"),
});

const CategoryToOptionMapper = site => (
  <option key={site.id} value={site.id}>
    {site.name}
  </option>
);

const ManagePublication = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { id } = useParams();
  const pageTitle = id ? "Modifiko publikimin" : "Krijo publikim";
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const updateForm = async () => {
    setLoading(true);
    const item = await queryRequest(() => publications.getForSave(id));
    setCategoriesOptions(item.availableCategories.map(CategoryToOptionMapper));
    item.categoryId = item.categoryIds.length > 0 ? item.categoryIds[0] : null;
    setItem(item);
    setLoading(false);
  };

  useEffect(() => {
    updateForm();
  }, []);

  const onSubmit = async values => {
    setSaveLoading(true);
    const result = await commandRequest(() => publications.save(values));
    setSaveLoading(false);

    if (!result.errors) {
      openNotification(
        "success",
        "Operacioni",
        "Ruajtja e publikimit u krye me sukses."
      );
      history.push(getSiteUrl("/publications"));
    } else {
      setErrors(result.errors);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card title={pageTitle}>
        <ValidationSummary errors={errors} />
        {item && (
          <Formik
            enableReinitialize={true}
            initialValues={item}
            validationSchema={contactFormValidator}
            onSubmit={onSubmit}
          >
            {props => (
              <FormikForm>
                <Row>
                  <Input
                    label="Titulli"
                    name="title"
                    type="text"
                    placeholder="Titulli i publikimit"
                    onBlur={e => {
                      props.setFieldValue(
                        "friendlyUrl",
                        generateFriendlyUrl(e.currentTarget.value)
                      );
                    }}
                  />
                </Row>

                <Row>
                  <Input
                    label="Url miqësore"
                    name="friendlyUrl"
                    type="text"
                    placeholder="Url miqësore"
                  />
                </Row>

                <Row>
                  <Editor
                    label="Përmbajtja"
                    initialValue={item.content}
                    name="content"
                    fieldName="content"
                  />
                </Row>

                <Row>
                  <Uploader
                    label="Dokument (Opsional)"
                    initialValue={item.documentId}
                    name="documentId"
                    resizeToWidth={640}
                    resizeToHeight={426}
                    fieldName="documentId"
                  />
                </Row>

                {/*<Row>*/}
                {/*  <Uploader label="Foto(640x426)(Opsionale)" initialValue={item.photoId} name="photoId"*/}
                {/*            resizeToWidth={640} resizeToHeight={426}*/}
                {/*            fieldName="photoId"/>*/}
                {/*</Row>*/}

                <Row>
                  {categoriesOptions.length > 0 && (
                    <Select
                      defaultValue={item.categoryId}
                      label="Kategoria"
                      placeholder="Zgjidh kategorinë"
                      name="categoryId"
                      itemName="categoryId"
                    >
                      <option value={null}>[Zgjidh kategori]</option>
                      {categoriesOptions}
                    </Select>
                  )}
                </Row>

                <Row>
                  <SaveCancelButtons loading={saveLoading} />
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </Spin>
  );
};

export default ManagePublication;
