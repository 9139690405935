import { Button } from "antd";
import React from "react";

const SaveButton = ({
  loading = false,
  saveTitle = "Ruaj",
  icon = "save",
  disabled = false,
  onClick = () => {},
  className = ""
}) => {
  return (
    <>
      <Button
        icon={icon}
        type="primary"
        disabled={disabled}
        htmlType="submit"
        onClick={onClick}
        className={className}
        loading={loading}
      >
        {saveTitle}
      </Button>
    </>
  );
};

export default SaveButton;
