import React from "react";
import styled from "styled-components";
import { useField } from "formik";
import { Input as AntInput } from "antd";
import Error from "./FormItemError";
import StyledLabel from "./StyledLabel";
const { TextArea } = AntInput;

const AntArea = styled(TextArea)`
  width: 70%;
`;

const Area = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel> <br />
      <AntArea rows={4} className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </>
  );
};

export default Area;
