import moment from "moment";

const displayDate = dateAsString => {
  return `${moment(dateAsString).format("DD/MM/YYYY")}`;
};

const displayDateAndHour = dateAsString => {
  return dateAsString
    ? `${moment(dateAsString).format("DD/MM/YYYY HH:mm")}`
    : "";
};

const displayNamedItem = namedItem => (namedItem ? namedItem.name : "");

export { displayDate, displayDateAndHour, displayNamedItem };
