import React from "react";
import { Menu, Icon } from "antd";
import SubMenu from "antd/es/menu/SubMenu";

export const categoriesMenu = (links, type = "sidebar", history) =>
  links.map(link =>
    link.children && link.children.length > 0 ? (
      <SubMenu
        title={<span className="submenu-title-wrapper">{link.name}</span>}
      >
        {link.children.map(child => (
          <Menu.Item key={child.key} onClick={() => history.push(child.path)}>
            {child.icon && type === "sidebar" && <Icon type={child.icon} />}
            <span>{child.name}</span>
          </Menu.Item>
        ))}
      </SubMenu>
    ) : (
      <Menu.Item key={link.key} onClick={() => history.push(link.path)}>
        {link.icon && type === "sidebar" && <Icon type={link.icon} />}
        <span>{link.name}</span>
      </Menu.Item>
    )
  );

export const usersMenu = (userRoute, type = "sidebar", history) => (
  <Menu.Item key={userRoute.key} onClick={() => history.push(userRoute.path)}>
    {userRoute.icon && type === "sidebar" && <Icon type={userRoute.icon} />}
    <span>{userRoute.name}</span>
  </Menu.Item>
);
